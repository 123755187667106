import axios from 'axios';
import firebase from 'firebase/compat/app';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { catchExceptionCallback, getConfig } from '../core/utilities';
import { setOperators } from '../store/reducers/rootSlice';

const useOperators = (loading) => {
  const config = getConfig();
  const dispatch = useDispatch();
  const getOperators = async () => {
    try {
      const token = await firebase.auth().currentUser.getIdToken();
      const response = await axios.get(`${config.resbutlerApis}/crm/operator/get-allOperators`, { headers: { 'client-id': config.client, authorization: `Bearer ${token}` } });
      dispatch(setOperators(response.data.operators));
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  useEffect(() => {
    if (!loading) getOperators();
  }, [loading]);
};

export default useOperators;

export const roles = Object.freeze({
  MOBILE_USER: 0,
  MOBILE_SEATING: 1,
  WAIT_STAFF: 2,
  SUPERVISOR: 4,
  RESTAURANT_MANAGER: 8,
  ADMIN: 16,
  SYSTEM: 32,
});

export function hasRole(existingRoles, role) {
  return existingRoles & role;
}

export function addRole(existingRoles, role) {
  return existingRoles | role;
}

export function removeRole(existingRoles, role) {
  return existingRoles - role;
}

export const friendlyRoles = Object.freeze([
  { key: "SYSTEM", name: "System" },
  { key: "ADMIN", name: "Admin" },
  { key: "RESTAURANT_MANAGER", name: "Restaurant Manager" },
  { key: "SUPERVISOR", name: "Supervisor" },
  { key: "WAIT_STAFF", name: "Wait Staff" },
  { key: "MOBILE_SEATING", name: "Mobile Seating" },
  { key: "MOBILE_USER", name: "Mobile User" },
]);

import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import 'react-datetime/css/react-datetime.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { Collapse, Nav, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import useUrlParams from '../../hooks/useUrlParams';
import * as actions from '../../store/actions/actions';
import ToggleFullscreen from '../Common/ToggleFullscreen';
import { StateType } from './../../store/reducers/reducers';
import { keys } from 'lodash';

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

const OmsHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [restaurantId, date] = useUrlParams();
  const history = useHistory();
  const [navItem, setNavItem] = useState('');
  const { restaurants, maestroMode, linkedTable, showHiddenDockets } = useSelector((state: StateType) => ({
    restaurants: state.root.restaurants,
    maestroMode: state.root.maestroMode,
    linkedTable: state.root.linkedTable,
    showHiddenDockets: state.settings.showHiddenDockets,
  }));

  useEffect(() => {
    getNavItemOnload();

    history.listen((location) => {
      const path = location.pathname.split('/')?.[1];
      setNavItem(path);

      dispatch(actions.changeSetting('isCollapsed', true));
    });
  }, []);

  function getNavItemOnload() {
    const path = window.location.pathname.split('/')?.[1];
    setNavItem(path);
  }

  function toggleCollapsed(e) {
    e.preventDefault();
    dispatch(actions.toggleSetting('isCollapsed'));
    resize();
  }

  function asideCollapsed(e) {
    e.preventDefault();
    dispatch(actions.toggleSetting('asideToggled'));
    resize();
  }

  function resize() {
    // all IE friendly dispatchEvent
    var evt = document.createEvent('UIEvents');
    evt.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(evt);
    // modern dispatchEvent way
    // window.dispatchEvent(new Event('resize'));
  }

  function toggleOffsidebar(e) {
    e.preventDefault();
    dispatch(actions.toggleSetting('offsidebarOpen'));
  }

  const renderRestaurantDropDown = () => (
    <select id="restaurant-selector" className="form-control mr-2 ml-1" style={{ height: '25px', width: '100%', padding: 0 }} value={restaurantId} onChange={(e) => history.push(`${window.location.pathname}#${date}/${e.target.value}`)}>
      <option value="">Please Select</option>
      {keys(restaurants).map((key) => (
        <option key={`restaurant-selector-${key}`} value={key}>
          {restaurants[key].name}
        </option>
      ))}
    </select>
  );

  return (
    <>
      <header className="topnavbar-wrapper">
        {/* START Top Navbar */}
        <nav className="navbar topnavbar navbar-expand-md navbar-dark">
          {/* START Left navbar */}
          <ul className="navbar-nav mr-auto flex-row">
            <li className="nav-item" style={{ cursor: 'pointer' }}>
              {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
              <span className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={toggleCollapsed}>
                <em className="fas fa-bars"></em>
              </span>
              {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
              <span className="nav-link sidebar-toggle d-md-none" onClick={asideCollapsed}>
                <em className="fas fa-bars"></em>
              </span>
            </li>
          </ul>
          <div className="navbar-header pl-4 pl-md-0 pl-sm-5">
            <div className="navbar-brand pl-4 pl-lg-0 pl-md-0 pl-sm-0">
              <div className="pr-0">
                <Link to={`/dockets#${date}/${restaurantId}`} className="pl-2">
                  <img className="header-logo header-logo--sm" src="img/WizButler-Logo-42px-sm.png" alt="App Logo" />
                  <img className="header-logo header-logo--lg" src="img/WizButler-Operations-logo.png" alt="App Logo" />
                  <span className={classNames({ 'nav-active': navItem == 'dockets' }, 'btn btn-link my-auto pl-md-3 text-white')} style={{ fontSize: '14px' }}>
                    Dockets
                  </span>
                </Link>
              </div>
            </div>
            <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
          </div>

          {/* END navbar header */}

          {/* START Nav wrapper */}
          <Collapse isOpen={isOpen} navbar>
            <Nav navbar className="mr-auto flex-column flex-lg-row"></Nav>
            <Nav className="flex-row" navbar>
              <div className="mr-3 align-content-center">{renderRestaurantDropDown()}</div>
              <ToggleFullscreen />
              <NavItem>
                <NavLink onClick={toggleOffsidebar}>
                  <em className="fa fa-th"></em>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
          {/* END Nav wrapper */}
        </nav>
        {/* END Top Navbar */}
        {/* <div className="btn-standard" style={{ height: '10px' }}></div> */}
        {history.location.pathname == '/dockets' || history.location.pathname == '/transactions' || history.location.pathname === '/' ? (
          <div
            className={classNames({
              'btn-standard ': maestroMode === 0,
              'btn-function': maestroMode === 2,
              'btn-sharedSpace': undefined,
              'btn-linkedTable': linkedTable,
              'btn-hiddenDockets': showHiddenDockets, // highlight button red when hidden dockets active
            })}
            style={{ height: '10px' }}
          ></div>
        ) : null}
      </header>
    </>
  );
};

OmsHeader.propTypes = {
  settings: PropTypes.object,
};

export default withRouter(OmsHeader);

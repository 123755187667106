// rootSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import pako from 'pako';
import { createSelector } from 'reselect';
import { OperationMode } from '../../components/Layout/Offsidebar';
import { DisplayType, OrderModeType, ProgressType } from '../../components/Layout/OmsSelector';
import { catchExceptionCallback, getConfig } from '../../core/utilities';
import { Booking } from '../../resbutler-utils/types/Booking';
import { OMSDisplayDocket } from '../../resbutler-utils/types/Docket';
import { MenuHeadings, Menus } from '../../resbutler-utils/types/Menu';
import { Operators } from '../../resbutler-utils/types/Operator';
import { BookingOrderingData, Orders } from '../../resbutler-utils/types/Order';
import { Printers } from '../../resbutler-utils/types/Printers';
import { Restaurants } from '../../resbutler-utils/types/Restaurant';
import { AdditionGroup, Modifier, PreparationGroup, Product, ProductGroups, ProductSizes, UpsellGroup } from '../../resbutler-utils/types/product';
import { OPERATION_MODE } from '../actions/root.actions';
import { StateType } from './reducers';

export interface RootReducerTypes {
  display: DisplayType;
  maestroMode: number;
  progress: ProgressType;
  restaurants: Restaurants;
  menus: Menus;
  batchSettings: {};
  omsWidth: number;
  orders: Orders;
  orderMode: OrderModeType;
  operationMode: OperationMode;
  highlightItemKey: string;
  allProducts: Product[];
  modifierGroups: Modifier[];
  preparationGroups: PreparationGroup[];
  upsellGroups: UpsellGroup[];
  additionGroups: AdditionGroup[];
  bookingsByMeal: { [mealId: string]: Booking[] };
  filterPrinterIds: string[];
  printers: Printers;
  operators: Operators;
  linkedTable: string;
  showSummary: boolean;
  dockets: OMSDisplayDocket[];
  productSizes: ProductSizes;
  menuHeadings: MenuHeadings;
  productGroups: ProductGroups;
  bookingsOrderingData: BookingOrderingData[];
}

const initialState: RootReducerTypes = {
  display: DisplayType.PrepDisplay,
  maestroMode: 0,
  progress: ProgressType.Active,
  restaurants: {},
  menus: {},
  batchSettings: {},
  orders: {},
  omsWidth: 0,
  orderMode: OrderModeType.SentOrder,
  operationMode: null,
  highlightItemKey: '',
  allProducts: [],
  modifierGroups: [],
  preparationGroups: [],
  upsellGroups: [],
  additionGroups: [],
  bookingsByMeal: {},
  filterPrinterIds: [],
  printers: {},
  operators: {},
  linkedTable: null,
  showSummary: false,
  dockets: [],
  productSizes: {},
  menuHeadings: {},
  productGroups: {},
  bookingsOrderingData: [],
};

export const getAllProducts = createAsyncThunk('root/getAllProducts', async () => {
  const { config, client } = getConfig();
  const response = await fetch(`https://storage.googleapis.com/${config.publicStorageBucket}/${client}/productData/products.json`);
  const arrayBuffer = await response.arrayBuffer();
  return JSON.parse(new TextDecoder().decode(pako.inflate(arrayBuffer)));
});

const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    setDisplay: (state, action) => {
      state.display = action.payload;
    },
    setOrderMode: (state, action) => {
      state.orderMode = action.payload;
    },
    setOperationMode: (state, action) => {
      localStorage.setItem(OPERATION_MODE, JSON.stringify(action.payload));
      state.operationMode = action.payload;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
    setRestaurants: (state, action) => {
      state.restaurants = action.payload;
    },
    setMenus: (state, action) => {
      state.menus = action.payload;
    },
    setBatchSettings: (state, action) => {
      state.batchSettings = action.payload;
    },
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    setOMSWidth: (state, action) => {
      state.omsWidth = action.payload;
    },
    setHighlightItemKey: (state, action) => {
      state.highlightItemKey = action.payload;
    },
    setModifierGroups: (state, action) => {
      state.modifierGroups = action.payload;
    },
    setPreparationGroups: (state, action) => {
      state.preparationGroups = action.payload;
    },
    setUpsellGroups: (state, action) => {
      state.upsellGroups = action.payload;
    },
    setAdditionGroups: (state, action) => {
      state.additionGroups = action.payload;
    },
    setBookingsByMeal: (state, action) => {
      state.bookingsByMeal = { ...state.bookingsByMeal, [action.payload.mealId]: action.payload.bookings };
    },
    setFilterPrinterIds: (state, action) => {
      state.filterPrinterIds = action.payload;
    },
    setPrinters: (state, action) => {
      state.printers = action.payload;
    },
    setOperators: (state, action) => {
      state.operators = action.payload;
    },
    setLinkedTable: (state, action) => {
      state.linkedTable = action.payload;
    },
    setDockets: (state, action) => {
      state.dockets = action.payload;
    },
    setProductSizes: (state, action) => {
      state.productSizes = action.payload;
    },
    setMenuHeadings: (state, action) => {
      state.menuHeadings = action.payload;
    },
    setProductGroups: (state, action) => {
      state.productGroups = action.payload;
    },
    setBookingsOrderingData: (state, action) => {
      state.bookingsOrderingData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllProducts.fulfilled, (state, action) => {
      state.allProducts = action.payload;
    });
    builder.addCase(getAllProducts.rejected, (state, action) => {
      catchExceptionCallback(action.error);
    });
  },
});

export const {
  setDisplay,
  setOrderMode,
  setOperationMode,
  setProgress,
  setRestaurants,
  setMenus,
  setBatchSettings,
  setOrders,
  setOMSWidth,
  setHighlightItemKey,
  setModifierGroups,
  setPreparationGroups,
  setUpsellGroups,
  setAdditionGroups,
  setBookingsByMeal,
  setFilterPrinterIds,
  setPrinters,
  setOperators,
  setLinkedTable,
  setDockets,
  setProductSizes,
  setMenuHeadings,
  setProductGroups,
  setBookingsOrderingData,
} = rootSlice.actions;

export const getOperationModeSelector = createSelector(
  (state: StateType) => state.root.operationMode,
  () => {
    const operationModeLocal = localStorage.getItem(OPERATION_MODE);
    return operationModeLocal ? JSON.parse(operationModeLocal) : OperationMode.OneStep;
  }
);

export default rootSlice.reducer;

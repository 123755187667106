import _, { filter, find, forEach, values } from "lodash";
import moment from "moment";
import { Booking } from "../types/Booking";
import { FunctionBooking } from "../types/FunctionBooking";
import { CourseGroup, MenuHeadings } from "../types/Menu";
import { Docket, DocketItemCombo, DocketItems, DocketPrintStatus, OrderItem, OrderModifier, Orders } from "../types/Order";
import { Printers } from "../types/Printers";
import { Customer } from "../types/customer";
import { Product, ProductGroups, ProductSizes, Products } from "../types/product";

interface CondolidatedOrderItem extends OrderItem {
  consolidatedItems?: { orderId: string; orderItemId: string }[];
}

export const generateDocket = (
  bookingId: string,
  functionBookingId: string,
  createdAt: number,
  docketGenerationTime: number,
  orderItems: OrderItem[],
  docketId: string,
  orders: Orders,
  printerAreaIds: string[],
  booking: Booking | FunctionBooking = null,
  orderNumber: number = null,
  bucketId: string = null,
  batchSettingId: string = null,
  courseGroupsForDocket: CourseGroup[] = [],
  canUseMenuHeadingToGroup: boolean,
  operatorId: string,
  products: Products,
  customers: Customer[],
  sizes: ProductSizes,
  menuHeadings: MenuHeadings,
  printers: Printers,
  productGroups: ProductGroups,
  restaurantId: string,
  zoneId: string,
  getNewDocketItemId: () => string
) => {
  if (orderItems.length === 0) return null;

  const docketItems: DocketItems = {};

  //If there is booking customer then use that name on docket else use customer on order.
  const order = values(orders)[0];
  const customer = booking ? find(customers, (c) => c._key === booking.customerId) : find(customers, (c) => c._key === order.customerId);
  let tableNumbers = {};
  if (booking && !("functionName" in booking) && customer) {
    tableNumbers[customer._key] = {
      customerName: customer.firstName,
      tableNumber: booking.tableNumber || "",
    };
  }
  if (booking) {
    booking.guests.forEach((guest) => {
      if (guest.customer?.firstName && guest.tableNumber) {
        tableNumbers[guest.customerId] = { customerName: guest.customer.firstName, tableNumber: guest.tableNumber };
      }
    });
  }

  const consolidatedOrderItems: CondolidatedOrderItem[] = consolidateOrderItems(orderItems, menuHeadings, tableNumbers);
  _.forEach(consolidatedOrderItems, (orderItem) => {
    const docketItemsId = getNewDocketItemId();
    const preparationsValue = getPreparationsData(orderItem?.preparations);
    const modifiersValue = getModifiersData(orderItem?.modifiers, sizes, products);
    const additionsValue = orderItem?.additions ? populateAdditionsNestedData(orderItem?.additions, sizes, products) : [];
    const comboValue = orderItem.comboGroup ? populateComboValue(orderItem.comboGroup, sizes, products) : [];
    const macAddresses = getProductMacAddresses(printers, productGroups, products[orderItem.productId], restaurantId);
    let courseGroupIndex = null;
    const orderItemHeadingId = orderItem.headingId;
    if (orderItem.courseGroupKey && (courseGroupsForDocket || []).length > 0) courseGroupIndex = _.findIndex(courseGroupsForDocket, (cg) => cg._key === orderItem.courseGroupKey);
    const customerNames = {};
    let tableNumber = "";
    if (orders[orderItem.orderId].customerId) {
      const customer = find(customers, (c) => c._key === orders[orderItem.orderId].customerId);
      if (customer) {
        if (customer.firstName) {
          customerNames[customer._key] = customer.firstName;
        }
        forEach(orderItem.consolidatedItems, ({ orderId }) => {
          if (orders[orderId].customerId) {
            const customer = find(customers, (c) => c._key === orders[orderId].customerId);
            if (customer.firstName && !(customer._key in customerNames)) {
              customerNames[customer._key] = customer.firstName;
            }
          }
        });
        tableNumber = tableNumbers[customer._key]?.tableNumber;
      }
    }

    docketItems[docketItemsId] = {
      name: products[orderItem.productId].docketName || products[orderItem.productId].name,
      size: sizes[orderItem.sizeId]?.name,
      hideSize: filter(products[orderItem.productId].sizes, (enabled) => enabled).length < 2 && !products[orderItem.productId].orderingRules?.showSingleSizeInDocket,
      heading: orderItemHeadingId && menuHeadings[orderItemHeadingId] ? menuHeadings[orderItemHeadingId].name : "",
      groupType: orderItemHeadingId && find(menuHeadings, (mh, menuHeadingId) => menuHeadingId === orderItemHeadingId) ? find(menuHeadings, (mh, menuHeadingId) => menuHeadingId === orderItemHeadingId).groupType : null,
      courseGroupIndex,
      courseGroupKey: orderItem.courseGroupKey || "",
      quantity: orderItem.quantity,
      preparations: preparationsValue,
      modifiers: modifiersValue,
      additions: additionsValue,
      combo: comboValue,
      orderId: orderItem.orderId,
      orderItemId: orderItem.orderItemId,
      printing: macAddresses,
      consolidatedItems: orderItem?.consolidatedItems || [],
      note: orderItem.note || null,
      customerNames,
      tableNumber,
    };
  });

  let courseGroups = [];
  if (courseGroupsForDocket && courseGroupsForDocket.length > 0) {
    courseGroups = courseGroupsForDocket.map((courseGroup) => courseGroup.name);
  }

  const docket: Docket = {
    _key: docketId,
    customerName: customer ? `${customer.firstName} ${customer.lastName}` : "",
    date: Number(order.date) ? Number(order.date) : Number(moment(docketGenerationTime).tz(zoneId).format("YYYYMMDD")),
    time: moment(docketGenerationTime).tz(zoneId).format("hh:mmA"),
    tableNo: order.bookingId && booking?.alg?.number ? `${booking.alg.number}` : null,
    printerAreaIds,
    orderType: order.orderType,
    pax: booking ? Number(booking.pax) + (Number(booking.extraPax) || 0) : null,
    printStatus: DocketPrintStatus.NotPrinted,
    createdAt,
    docketGenerationTime,
    bucketId,
    batchSettingId,
    bookingId,
    functionBookingId,
    bookingRef: order.bookingRef,
    restaurantId: order.restaurantId,
    docketItems,
    orderSource: order.orderSource,
    orderNumber: orderNumber || null,
    hidden: {
      one: false,
      prep: false,
      serve: false,
    },
    courseGroups,
    canUseMenuHeadingToGroup: canUseMenuHeadingToGroup === true,
    operatorId: operatorId ? operatorId : "",
  };
  return docket;
};

export function populateAdditionsProductIds(additions, productIds) {
  _.forEach(additions, (additionProduct, additionGroupId) => {
    _.forEach(additionProduct, (addition, additionProdutId) => {
      productIds.push(additionProdutId);
      if (addition?.modifiers) Object.values(addition?.modifiers || []).forEach((modifier: OrderModifier) => productIds.push(modifier.productId));
      if (addition?.additions) populateAdditionsProductIds(addition?.additions, productIds);
    });
  });
}

function consolidateOrderItems(
  currentOrderItems: OrderItem[],
  menuHeadings: MenuHeadings,
  tableNumbers: {
    [customerId: string]: { customerName: string; tableNumber: string };
  }
) {
  const addedDuplicates = [];
  const docketItems: CondolidatedOrderItem[] = [];
  for (let j = 0; j < currentOrderItems.length; j++) {
    if (addedDuplicates.includes(currentOrderItems[j].orderItemId)) continue;
    const duplicates = currentOrderItems.filter((coi) => {
      const customerTableNumber1 = tableNumbers[coi.customerId]?.tableNumber;
      const customerTableNumber2 = tableNumbers[currentOrderItems[j].customerId]?.tableNumber;
      if (customerTableNumber1 && customerTableNumber2 && customerTableNumber1 !== customerTableNumber2) return false;
      return (
        coi.courseGroupKey === currentOrderItems[j].courseGroupKey &&
        coi.headingId === currentOrderItems[j].headingId &&
        coi.productId == currentOrderItems[j].productId &&
        coi.sizeId === currentOrderItems[j].sizeId &&
        // !coi.additions &&
        // !coi.modifiers &&
        // !coi.preparations &&
        // !currentOrderItems[j].additions &&
        // !currentOrderItems[j].modifiers &&
        // !currentOrderItems[j].preparations &&
        _.isEqual(coi.modifiers, currentOrderItems[j].modifiers) &&
        _.isEqual(coi.additions, currentOrderItems[j].additions) &&
        _.isEqual(coi.upsells, currentOrderItems[j].upsells) &&
        _.isEqual(coi.preparations, currentOrderItems[j].preparations) &&
        _.isEqual(coi.comboGroup, currentOrderItems[j].comboGroup) &&
        _.isEqual(coi.note, currentOrderItems[j].note)
      );
    });
    if (duplicates.length > 1) {
      addedDuplicates.push(...duplicates.map((d) => d.orderItemId));
      const totalQuantity = duplicates.reduce((sum, d) => sum + d.quantity, 0);
      docketItems.push({
        ...duplicates[0],
        quantity: totalQuantity,
        consolidatedItems: duplicates.map((du) => ({
          orderId: du.orderId,
          orderItemId: du.orderItemId,
          customerId: du.customerId,
        })),
      });
    } else docketItems.push(currentOrderItems[j]);
  }
  return _.orderBy(docketItems, (di) => (di.headingId && menuHeadings[di.headingId] ? menuHeadings[di.headingId].name : ""), "asc");
}

function getModifiersData(modifiers, sizes, products, forAddition = false) {
  const modifiersValue = [];
  Object.values(modifiers || []).forEach((modifier: OrderModifier) => {
    const quantity = modifier?.quantity;
    const productName = products[modifier.productId]?.menuDisplayName || products[modifier.productId].name;
    const size = sizes?.[modifier?.productSizeId] ? `${sizes?.[modifier.productSizeId].name}` : "";
    modifiersValue.push({ name: productName, quantity, size });
  });
  return modifiersValue;
}

function populateAdditionsNestedData(additions, sizes, products) {
  const additionsValue = [];
  _.forEach(additions, (additionProduct) => {
    _.forEach(additionProduct, (addition, additionProdutId) => {
      const newAddition: any = {};
      const additionData = getAdditionData({ ...addition, productId: additionProdutId }, sizes, products);
      newAddition.name = additionData.name;
      newAddition.quantity = additionData.quantity;
      if (addition?.modifiers) newAddition.modifiers = [...getModifiersData(addition.modifiers, sizes, products)];
      if (addition?.preparations) newAddition.preparations = [...getPreparationsData(addition.preparations)];
      if (addition?.additions) newAddition.additions = [...populateAdditionsNestedData(addition?.additions, sizes, products)];
      additionsValue.push(newAddition);
    });
  });
  return additionsValue;
}

function populateComboValue(comboGroup: { [productId: string]: OrderItem }, sizes: ProductSizes, products: Products): DocketItemCombo[] {
  const combo: DocketItemCombo[] = [];
  forEach(comboGroup, (cg, productId) => {
    const quantity = cg.quantity || null;
    const productName = products[productId].docketName || products[productId].name;
    const size = sizes?.[cg.sizeId] ? `${sizes?.[cg.sizeId].name}` : "";
    const preparationsValue = getPreparationsData(cg.preparations);
    const modifiersValue = getModifiersData(cg.modifiers, sizes, products);
    const additionsValue = cg.additions ? populateAdditionsNestedData(cg.additions, sizes, products) : [];
    combo.push({ additions: additionsValue, preparations: preparationsValue, modifiers: modifiersValue, name: productName, size, quantity });
  });
  return combo;
}

function getAdditionData(addition, sizes, products) {
  const quantity = addition?.quantity || null;
  const productName = products[addition.productId]?.menuDisplayName || products[addition.productId].name;
  const size = sizes?.[addition?.sizeId] ? `${sizes?.[addition.sizeId].name}` : "";
  return { quantity, name: productName, size };
}

function getPreparationsData(preparations, forAddition = false) {
  const preparationsValue = [];
  Object.values(preparations || []).forEach((preparation: any[]) => {
    preparation.forEach((p) => {
      if (forAddition) preparationsValue.push({ name: p, quantity: "" });
      else preparationsValue.push(p);
    });
  });
  return preparationsValue;
}

function getProductMacAddresses(printers: Printers, productGroups, product: Product, restaurantId: string) {
  let docketSettings = product?.docketSettings?.[restaurantId] ? product.docketSettings[restaurantId] : {};
  const overrideGroupDocketSettings = product?.overrideGroupDocketSettings?.[restaurantId] ? product.overrideGroupDocketSettings[restaurantId] : false;
  let productGroup;
  let parentGroup = product.groupId;
  const activeMacAddresses = [];
  while (parentGroup != "-1" || Object.keys(docketSettings || {}).length > 0) {
    _.keys(docketSettings).forEach((printerId) => {
      if (docketSettings[printerId]) {
        const macAddress = printers[printerId]?.macAddress || "";
        if (macAddress && docketSettings[printerId] && !activeMacAddresses.find((ma) => ma.macAddress == macAddress))
          activeMacAddresses.push({
            macAddress,
            printStatus: DocketPrintStatus.NotPrinted,
          });
      }
    });
    if (overrideGroupDocketSettings) break;
    productGroup = parentGroup == "-1" ? {} : productGroups[parentGroup];
    parentGroup = productGroup?.parentGroup || "-1";
    docketSettings = productGroup?.docketSettings?.[restaurantId] || {};
  }
  return activeMacAddresses;
}

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Spinner } from 'reactstrap';
import { catchExceptionCallback } from '../../core/utilities';
import * as actions from '../../store/actions/actions';
import { toggleSetting } from '../../store/actions/settings.actions';
import { getOperationModeSelector, setOperationMode } from '../../store/reducers/rootSlice';
import ToggleOptions from '../Common/ToggleOptions';
import useUrlParams from './../../hooks/useUrlParams';
import { StateType } from './../../store/reducers/reducers';
export enum OperationMode {
  AllStep,
  OneStep,
}

const Offsidebar = () => {
  const { restaurants, settings, operationMode } = useSelector(
    (state: StateType) => ({
      restaurants: state.root.restaurants,
      settings: state.settings,
      operationMode: getOperationModeSelector(state),
    }),
    shallowEqual
  );
  let options = [
    {
      displayName: 'One Step',
      value: OperationMode.OneStep,
    },
    {
      displayName: 'All Steps',
      value: OperationMode.AllStep,
    },
  ];
  const [restaurantId] = useUrlParams();
  const [offsidebarReady, setOffsidebarReady] = useState<boolean>(false);
  const [restaurantId1, setRestaurant1Id] = useState<string>(restaurantId);
  const [loadingLogout, setLoadingLogout] = useState<boolean>(false);
  const dispatch = useDispatch();
  const onClick = useCallback((e) => dispatch(setOperationMode(e.target.checked ? options[1].value : options[0].value)), []);

  function logout() {
    setLoadingLogout(true);
    firebase
      .auth()
      .signOut()
      .then(() => {
        setLoadingLogout(false);
        window.location.href = '/login';
      })
      .catch((error) => {
        catchExceptionCallback(error);
        setLoadingLogout(false);
      });
  }

  useEffect(() => {
    setRestaurant1Id(restaurantId);
    setOffsidebarReady(true);
  }, [restaurantId, settings.offsidebarOpen]);

  const closeSidebar = (e: any) => {
    if (e.target.classList.contains('offsidebar')) {
      dispatch(toggleSetting('offsidebarOpen'));
    }
  };

  const renderOperationMode = () => {
    return <ToggleOptions onClick={onClick} on={options[1].displayName} off={options[0].displayName} width="100%" active={operationMode === OperationMode.AllStep} />;
  };

  return offsidebarReady ? (
    <aside className="offsidebar" onClick={closeSidebar}>
      <div className="aside-inner">
        {/* START Off Sidebar (right) */}
        <div className="container mt-4">
          <Row className="row-bordered">
            <Col lg={12}>
              <div className="form-group">
                <label>Restaurant</label>
                <Row>
                  <Col lg={12}>
                    <select id="restaurant-selector" className="form-control mr-2" value={restaurantId1} onChange={(e) => setRestaurant1Id(e.target.value)}>
                      <option value="">Please Select</option>
                      {Object.keys(restaurants).map((key) => (
                        <option key={`restaurant-selector-${key}`} value={key}>
                          {restaurants[key].name}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row className="align-content-center">
            <div className="container-fluid font-weight-bold ms-2 mb-1">Display Preference</div>
            <div className="container-fluid my-1">{renderOperationMode()}</div>
            <div className="container-fluid my-1">
              <ToggleOptions onClick={(e) => dispatch(actions.changeSetting('showCustomerName', e.target.checked))} on="Show customer name" off="Hide customer name" width="100%" active={settings.showCustomerName} />
            </div>
          </Row>
          <Row className="row-bordered">
            <Col lg={12} xs={6} className="mt-5">
              <Button type="button" style={{ color: 'white', backgroundColor: 'black' }} className="btn-lg col-lg-12 float-right align-bottom" onClick={() => logout()}>
                <em className="icon-logout"></em>
                <span className="ml-2" style={{ whiteSpace: 'nowrap' }}>
                  Logout {loadingLogout && <Spinner size="sm">{""}</Spinner>}
                </span>
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </aside>
  ) : null;
};

Offsidebar.propTypes = {
  settings: PropTypes.object,
};

export default Offsidebar;

import { parsePhoneNumber } from "libphonenumber-js";
import _, { assign, chain, filter, find, findKey, isNil, keys, pickBy, values } from "lodash";
import { Moment, default as moment, default as momentTz } from "moment-timezone";
import _statuses from "./files/status.json";
import { Booking } from "./types/Booking";
import { Area, AreaGroup, Areas, Box, Boxes, Table } from "./types/Box";
import { AreaFlips, FloorPlanAvailability } from "./types/FloorPlan";
import { FunctionBooking } from "./types/FunctionBooking";
import { Guest } from "./types/Guest";
import { Label, Labels } from "./types/Labels";
import { Maestro, MaestroDate } from "./types/Maestro";
import { BatchSetting, Course, CourseGroup, Courses, GroupHeading, GroupHeadings, Menu, MenuPayments, Menus, Packages, PaymentRequirementTypes } from "./types/Menu";
import { BatchingBuckets, Bucket, OrderItem, OrderItemStatus, PayLaters } from "./types/Order";
import { AvailableTabPayment, PaymentTab } from "./types/PaymentTab";
import { Restaurant } from "./types/Restaurant";
import { Intervals, OpeningTimes, Seating, Seatings, Style } from "./types/Seating";
import { Customer, Customers } from "./types/customer";
import { Product, Products } from "./types/product";
import { LineItemTypeId, PaymentTypeId, Transaction } from "./types/transaction";

export function anyIntersection(obj1, obj2) {
  if (obj1 && obj2) {
    const length1 = Object.keys(obj1).length;
    const length2 = Object.keys(obj2).length;
    const compare1 = length1 <= length2 ? obj1 : obj2;
    const compare2 = length1 <= length2 ? obj2 : obj1;
    for (const k in compare1) {
      if (compare2[k]) {
        return true;
      }
    }
  }
  return false;
}

export function getEnumName<T>(enumType: T, value: number): keyof T | undefined {
  for (const key in enumType) {
    if (enumType[key as string] === value) {
      return key as keyof T;
    }
  }
  return undefined; // If no match is found
}

export function getEndTime(booking: Booking | FunctionBooking) {
  if (!booking) return 0;
  const _date2 = Number(booking.intervalId);
  return _date2 + booking.alg.duration * 60 * 1000;
}

// static getThresholdLeftRight(box: Table, rule, threshold, override = false) {
//   let thresholdReached = rule.physicalMaxThreshold && (threshold >= rule.physicalMaxThresholdValue || override);
//   // if ((!rule.prioritizeHeads) || (rule.prioritizeHeads)) {
//   //   thresholdReached = false;
//   // }
//   let thresholdReachedLeft = true,
//     thresholdReachedRight = true;
//   if (!thresholdReached) {
//     thresholdReachedLeft = box.thresholdOverrideLeft !== undefined && threshold >= Number(box.thresholdOverrideLeft);
//     thresholdReachedRight = box.thresholdOverrideRight !== undefined && threshold >= Number(box.thresholdOverrideRight);
//   }
//   return [thresholdReachedLeft, thresholdReachedRight];
// }

export function getThresholdLeftRight(box: Table, rule, threshold, override = false, staticOnly = false) {
  if (staticOnly) return [false, false];
  const thresholdReached = rule.physicalMaxThreshold && (threshold >= rule.physicalMaxThresholdValue || override);
  let thresholdReachedLeft = thresholdReached;
  let thresholdReachedRight = thresholdReached;
  if (box.thresholdOverrideLeft !== undefined && !override) thresholdReachedLeft = threshold >= Number(box.thresholdOverrideLeft);
  if (box.thresholdOverrideRight !== undefined && !override) thresholdReachedRight = threshold >= Number(box.thresholdOverrideRight);
  return [thresholdReachedLeft, thresholdReachedRight];
}

function defaultCourseDuration(booking: Booking | FunctionBooking, defaultMenus, mealId) {
  if (defaultMenus) {
    const defaultMenuId = _.findKey(defaultMenus, (df) => df.mealId === mealId);
    if (defaultMenuId && defaultMenus[defaultMenuId].durations) {
      const durationId = _.findKey(defaultMenus[defaultMenuId].durations, (d) => booking.pax >= d.minPax && Number(booking.pax) <= d.maxPax);
      if (durationId) {
        return defaultMenus[defaultMenuId].durations[durationId].duration;
      }
    }
  }
  return 120;
}

export function findCategorizedProductById(butlerServiceGroups, productId) {
  for (let i = 0; i < butlerServiceGroups.length; i++) {
    const pc = butlerServiceGroups[i];
    for (let j = 0; j < pc.subcategories.length; j++) {
      if (pc.subcategories[j].butlerProducts) {
        const productIndex = _.findIndex(pc.subcategories[j].butlerProducts, (p: any) => p.productId === productId);
        if (productIndex > -1) return pc.subcategories[j].butlerProducts[productIndex];
      }
    }
  }
  return null;
}

export function containsPrepayment(categories, butlerServiceGroups) {
  let prepayment = null;
  if (categories) {
    for (let i = 0; i < categories.length; i++) {
      if (!categories[i]) {
        categories[i] = { productId: "", subcategoryId: "", quantity: 0 };
      } else {
        const cat = categories[i];
        const product = findCategorizedProductById(butlerServiceGroups, cat.productId);
        if (cat && cat.productId && cat.quantity > 0 && product) {
          if (product.butlerService) {
            if (product.butlerService && product.butlerService[cat.productSizeId] && product.butlerService[cat.productSizeId].requiredPrepayment) {
              prepayment = true;
            }
          }
        }
      }
    }
  }
  return prepayment;
}

export function getDuration(booking: Booking | FunctionBooking, seating: Seating, course: Course, defaultMenus, style: Style, level = 0, timeProduct = null): number {
  const reset = seating ? Number(seating.reset || 0) : 0;
  let end = null;
  if (booking.statusChangeData && booking.statusChangeData[_statuses.vacated.text] && booking.statusChangeData[_statuses.vacated.text] - Number(booking.intervalId) > 0) {
    return (booking.statusChangeData[_statuses.vacated.text] - Number(booking.intervalId)) / (1000 * 60);
  } else if (booking.endTime) {
    return (booking.endTime - Number(booking.intervalId)) / (60 * 1000) + reset;
  } else if (seating && seating.useSeatingEndTime) {
    end = (Number(seating.close) - Number(booking.intervalId)) / (60 * 1000);
  } else if (course && course.durations) {
    for (const d in course.durations) {
      const duration = course.durations[d];
      if (Number(duration.minPax) <= Number(booking.pax) && Number(duration.maxPax) >= Number(booking.pax)) {
        switch (level) {
          case 2:
            end = Number(duration.vip) + reset;
            break;
          case 1:
            end = Number(duration.supervip) + reset;
            break;
          default:
            end = Number(duration.standard) + reset;
            break;
        }
      }
    }
  }
  if (!end) {
    end = defaultCourseDuration(booking, defaultMenus, booking.mealId) + reset;
  }
  if (timeProduct) {
    return end + (timeProduct ? Number(timeProduct.timeValue) : 0);
  }
  const endTime = Number(booking.intervalId) + end * 60 * 1000;
  if (style && style.useHardEndTime && endTime > style.hardEndTime) {
    return (style.hardEndTime - Number(booking.intervalId)) / (60 * 1000);
  }
  return end;
}

export function makeId(length = 6) {
  let text = "";
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base) {
  return _.transform(object, (result, value, key) => {
    if (!_.isEqual(value, base[key])) {
      result[key] = _.isObject(value) && _.isObject(base[key]) ? difference(value, base[key]) : value;
    }
  });
}

function getIntervalIdByTicks(seating: Seating, start) {
  for (const intervalId in seating.intervals) {
    if (seating.intervals[intervalId].enabled === true && Number(intervalId) > start) {
      return intervalId;
    }
  }
  return "";
}

export function getMaestroNow(seatings: Seatings, date, restaurantId, zoneId): { date: string; restaurantId: string; mealId: string } {
  const seatings1 = _.chain(seatings).values().orderBy(["open"]).value();
  const now = momentTz.tz(zoneId);
  const date1 = momentTz.tz(date, zoneId);
  const isToday = now.isSame(date1, "day");
  if (isToday === true) {
    for (let i = 0; i < seatings1.length; i++) {
      const seating = seatings1[i];
      const start = (now.hour() * 3600 + now.minute() * 60) * 1000;
      const intervalId = getIntervalIdByTicks(seating, start);
      if (intervalId) {
        return { date: date1.format("YYYYMMDD"), restaurantId, mealId: seating.mealId };
      }
    }
  } else {
    for (let i = 0; i < seatings1.length; i++) {
      const seating = seatings1[i];
      const intervalId = getIntervalIdByTicks(seating, 0);
      if (intervalId) {
        return { date: date1.format("YYYYMMDD"), restaurantId, mealId: seating.mealId };
      }
    }
  }
  return { date: date1.format("YYYYMMDD"), restaurantId, mealId: "" };
}

export function getBoxesFromFloorPlanAvailability(availability: FloorPlanAvailability, boxes: Boxes) {
  let floorPlanIds = [];
  if (availability.defaultFloorPlanId) floorPlanIds = [availability.defaultFloorPlanId];
  floorPlanIds = [...floorPlanIds, ...(availability.additionalFloorPlanIds || [])];
  const boxes1: Boxes = {};
  for (let i = 0; i < floorPlanIds.length; i++) {
    const floorPlanId = floorPlanIds[i];
    Object.assign(
      boxes1,
      _.pickBy(boxes, (b) => {
        if (b.areaVariationId === availability.areaVariations?.[floorPlanId]?.[b.areaId]?.defaultAreaVariationId) return true;
        if (_.findIndex(availability.areaVariations?.[floorPlanId]?.[b.areaId]?.additionalAreaVarationIds, (areaVariationId) => areaVariationId === b.areaVariationId) > -1) return true;
        return false;
      })
    );
  }
  return boxes1;
}

export function getBoxesByAreaVariationId(boxes: Boxes | Box[], areas: Areas, availability: FloorPlanAvailability, areaFlips: AreaFlips, areaFlipBoxes: Boxes, ticks) {
  let timeId = "";
  if (areaFlips) {
    const times = _.chain(areaFlips)
      .keys()
      .map((t) => t.substr(1, t.length - 1))
      .value()
      .sort()
      .reverse();
    const time = _.find(times, (t) => t <= ticks);
    if (!time) timeId = `s${times[times.length - 1]}`;
    else timeId = `s${time}`;
  }

  const isArray = Array.isArray(boxes);
  let boxes1: Boxes = {};
  if (isArray) {
    boxes1 = _.reduce(
      boxes,
      (obj, param) => {
        obj[param._key] = param;
        return obj;
      },
      {}
    );
    boxes1 = { ...boxes1, ...areaFlipBoxes };
  } else {
    boxes1 = { ...boxes, ...areaFlipBoxes };
  }

  const allBoxes: Boxes = {};
  const floorPlanId = areaFlips?.[timeId]?.floorPlanId || availability?.defaultFloorPlanId;
  for (const areaId in areas) {
    const index = _.findIndex(areaFlips?.[timeId]?.areaVariations, (av) => av.areaId === areaId);
    if (index > -1) {
      const av = areaFlips?.[timeId]?.areaVariations[index];
      const areaBoxes: Boxes = _.pickBy(boxes1, (a) => a.enabled !== false && a.areaId === areaId && a.areaVariationId === av.areaVariationId);
      Object.assign(allBoxes, areaBoxes);
    } else {
      const areaBoxes: Boxes = _.pickBy(boxes1, (a) => a.enabled !== false && a.areaId === areaId && a.areaVariationId === availability.areaVariations?.[floorPlanId]?.[areaId]?.defaultAreaVariationId);
      Object.assign(allBoxes, areaBoxes);
    }
  }
  return isArray ? _.map(allBoxes, (value, key) => _.extend({}, value, { _key: key })) : allBoxes;
}

export function getSeatingId(seatings: Seatings, intervalId) {
  for (const seatingId in seatings) {
    const seating = seatings[seatingId];
    if (seating.intervals && seating.intervals[intervalId]) {
      return seatingId;
    }
  }
  return "";
}

export function isIgnored(label: Label, zoneId, date) {
  const day = momentTz.tz(date, "YYYYMMDD", zoneId).format("dddd");
  return label.range === "range" ? !!label.ignored && label.ignored[day] === true : label.discreteDates && label.discreteDates.findIndex((discrete) => discrete.ignored === true && String(discrete.date) === momentTz.tz(date, "YYYYMMDD", zoneId).format("YYYYMMDD")) > -1;
}

export function isClosed(label: Label, zoneId, date) {
  const m = momentTz.tz(date, "YYYYMMDD", zoneId).format("dddd");
  return label.range === "range" ? !!label.closed && label.closed[m] === true : label.discreteDates && label.discreteDates.findIndex((discrete) => discrete.closed === true && discrete.date === date) > -1;
}

export function getPastLabelId(label: Label, labelId, zoneId, date, restaurantId, mealId) {
  if (!isClosed(label, zoneId, date) && label.restaurantId === restaurantId && label.production && label.mealId === mealId) {
    return labelId;
  }
  return "";
}

export function getLabelId(labels: Labels, zoneId, date: string, restaurantId, mealId) {
  const labels1: Labels = _.pickBy(labels, (label, lId) => {
    return label.enabled && !isIgnored(label, zoneId, date) && label.restaurantId === restaurantId && label.production && label.mealId === mealId && (label.range === "range" ? label.start <= momentTz.tz(date, "YYYYMMDD", zoneId).startOf("day").valueOf() && label.end >= momentTz.tz(String(date), "YYYYMMDD", zoneId).startOf("day").valueOf() : label.discreteDates?.findIndex((discrete) => String(discrete.date) === date) > -1);
  });
  const labelsSorted = _.chain(labels1)
    .map((label: Label, key) => ({ ...label, _key: key }))
    .sortBy((label) => label.order)
    .value() as Label[];
  const label: Label = _.first(labelsSorted);
  if (label) {
    return label._key;
  }
  return "";
}

export function getDateLabelId(labels: Labels, zoneId, date, restaurantId) {
  const labels1 = _.pickBy(labels, (label, lId) => {
    return label.enabled && !isIgnored(label, zoneId, date) && label.restaurantId === restaurantId && label.production && (label.range === "range" ? label.start <= momentTz.tz(date, "YYYYMMDD", zoneId).startOf("day").valueOf() && label.end >= momentTz.tz(date, "YYYYMMDD", zoneId).startOf("day").valueOf() : label.discreteDates.findIndex((discrete) => String(discrete.date) === date) > -1);
  });
  const labelsSorted = _.chain(labels1)
    .map((label, key) => ({ ...label, _key: key }))
    .sortBy((label) => label.order)
    .value();
  const label = _.first(labelsSorted);
  if (label) {
    return label._key;
  }
  return "";
}

export function getMealLabelIds(labels: Labels, zoneId, date: string, restaurantId) {
  const groupLabels = _.chain(labels)
    .pickBy((label) => label.restaurantId === restaurantId)
    .groupBy("mealId")
    .value();
  const mealLabels: Labels = {};
  for (const mealId in groupLabels) {
    const labelsFiltered: Labels = _.pickBy(labels, (label, k) => {
      return label.mealId === mealId && label.enabled && !isIgnored(label, zoneId, date) && label.restaurantId === restaurantId && label.production && (label.range === "range" ? label.start <= momentTz.tz(date, "YYYYMMDD", zoneId).startOf("day").valueOf() && label.end >= momentTz.tz(date, "YYYYMMDD", zoneId).startOf("day").valueOf() : label.discreteDates && label.discreteDates.findIndex((discrete) => String(discrete.date) === date) > -1);
    });
    const labelId = getLabelId(labelsFiltered, zoneId, date, restaurantId, mealId);
    if (labelId && !isClosed(labelsFiltered[labelId], zoneId, Number(date))) {
      mealLabels[labelId] = labelsFiltered[labelId];
    }
  }
  return mealLabels;
}

export function getAreaId(placements, areas: Area[], rowIndex, colIndex) {
  return _.findKey(placements, (placement, areaId) => {
    if (_.findIndex(areas, (area) => area._key === areaId && area.enabled) >= 0) {
      return placement.x === colIndex && placement.y === rowIndex;
    }
    return false;
  });
}

export function getIntervalsFromSeatings(seatings: Seatings) {
  const results: Intervals = {};
  for (const seatingId in seatings) {
    const seating = seatings[seatingId];
    const intervals = _.pickBy(seating.intervals, (interval) => interval.enabled === true);
    Object.assign(results, intervals);
  }
  return results;
}

export function getCoursesByMealId(courses: Courses, menus: Menus) {
  return _.pickBy(courses, (c: Course) => menus[c.menuId]);
}

export function getBookingsAtOneTime(interval: number, bookings: (Booking | FunctionBooking)[]) {
  return _.filter(bookings, (b) => !b.alg.waitlist && Number(b.intervalId) <= interval && getEnd(b) > interval);
}

export function isBeveragePaymentRequired(booking: Booking, transactions: Transaction[], packages: Packages) {
  return isAdultBeveragePaymentRequired(booking, transactions, packages) || isChildBeveragePaymentRequired(booking, transactions, packages);
}

export function isAdultBeveragePaymentRequired(booking: Booking, transactions: Transaction[], packages: Packages) {
  if (booking.adultBeveragePackageId && packages[booking.adultBeveragePackageId]?.standardRequiresPrepayment === true) {
    const index = _.findIndex(transactions, (t) => _.findIndex(t.lineItems, (li) => li.lineItemTypeId === LineItemTypeId.Package && li.id1 === booking.adultBeveragePackageProductId && li.id2 === booking.adultBeveragePackageId) > -1);
    if (index === -1) return true;
  }
  return false;
}

export function isChildBeveragePaymentRequired(booking: Booking, transactions: Transaction[], packages: Packages) {
  if (booking.childBeveragePackageId && packages[booking.childBeveragePackageId]?.standardRequiresPrepayment === true) {
    const index = _.findIndex(transactions, (t) => _.findIndex(t.lineItems, (li) => li.lineItemTypeId === LineItemTypeId.Package && li.id1 === booking.childBeveragePackageProductId && li.id2 === booking.childBeveragePackageId) > -1);
    if (index === -1) return true;
  }
  return false;
}

export function isAdultBeveragePaid(booking: Booking, transactions: Transaction[]) {
  const index = _.findIndex(transactions, (t) => _.findIndex(t.lineItems, (li) => li.lineItemTypeId === LineItemTypeId.Package && li.id1 === booking.adultBeveragePackageProductId && li.id2 === booking.adultBeveragePackageId) > -1);
  return index > -1;
}

export function isChildBeveragePaid(booking: Booking, transactions: Transaction[]) {
  const index = _.findIndex(transactions, (t) => _.findIndex(t.lineItems, (li) => li.lineItemTypeId === LineItemTypeId.Package && li.id1 === booking.childBeveragePackageProductId && li.id2 === booking.childBeveragePackageId) > -1);
  return index > -1;
}

export function isMenuPaymentRequired(booking: Booking, transactions: Transaction[], menuPayments: MenuPayments) {
  if (!isNil(menuPayments?.paymentRequirementTypeId)) {
    if (menuPayments.paymentRequirementTypeId === PaymentRequirementTypes.CreditCardDetails && (booking.creditCardDetailsConfirmed || booking.ccOverride)) {
      // CC details only
      return false;
    }
    let isDisabled = false;
    if (transactions) {
      for (let i = 0; i < transactions.length; i++) {
        isDisabled = _.findIndex(transactions[i].lineItems, (t) => t.lineItemTypeId === LineItemTypeId.Menu || t.lineItemTypeId === LineItemTypeId.Deposit) > -1;
        if (isDisabled) break;
      }
    }
    const paymentType = menuPayments.paymentTypes[menuPayments.paymentRequirementTypeId];
    if (!isDisabled && paymentType) {
      return true;
    }
  }
  return false;
}

export const getNoShowFee = (numAdults: number, numChildren: number, booking: Booking, menu: Menu, products: Product[]) => {
  let adultFee = 0;
  let childFee = 0;
  if (booking.menuId && !booking?.alg?.onHold) {
    const pt = menu?.menuPayments?.paymentTypes?.[menu?.menuPayments?.paymentRequirementTypeId];
    if (pt?.enableNoShow) {
      if (pt.noShowFeeTypeId === 3) {
        // $
        adultFee = pt.adultNoShowFeeAmount * numAdults;
        childFee = pt.childNoShowFeeAmount * numChildren;
      } else if (pt.noShowFeeTypeId === 1 || pt.noShowFeeTypeId === 2) {
        // %
        let product = find(products, (p) => p.id === booking.menuOptionId);
        if (product) {
          const price = Number(product.restaurants[booking.restaurantId].price[product.fixedPriceMenuTypes.adult]);
          adultFee = (pt.adultNoShowFeeAmount / 100) * price;
          for (let j = 0; j < (booking.guests || []).length; j++) {
            const guest = booking.guests[j];
            product = find(products, (p) => p.id === guest.menuOptionId);
            if (product) {
              if (!guest.childOrDependent) {
                adultFee += (pt.adultNoShowFeeAmount / 100) * Number(product.restaurants[booking.restaurantId].price[product.fixedPriceMenuTypes.adult] as number);
              } else {
                childFee += (pt.childNoShowFeeAmount / 100) * Number(product.restaurants[booking.restaurantId].price[product.fixedPriceMenuTypes.adult] as number);
              }
            }
          }
          const func = pt.roundingTypeId === 1 ? Math.ceil : Math.floor;
          if (pt.nearestTypeId === 1) {
            // nearest $1
            adultFee = func(adultFee / 1) * 1;
            childFee = func(childFee / 1) * 1;
          } else if (pt.nearestTypeId === 2) {
            // nearest $5
            adultFee = func(adultFee / 5) * 5;
            childFee = func(childFee / 5) * 5;
          } else if (pt.nearestTypeId === 3) {
            // nearest $10
            adultFee = func(adultFee / 10) * 10;
            childFee = func(childFee / 10) * 10;
          }
        }
      }
    }
  }
  return { adultFee: +adultFee.toFixed(2), childFee: +childFee.toFixed(2) };
};

export const getCancellationFee = (numAdults: number, numChildren: number, booking: Booking, menu: Menu, products: Product[]) => {
  let adultFee = 0;
  let childFee = 0;
  if (booking.menuId && !booking?.alg?.onHold) {
    const pt = menu?.menuPayments?.paymentTypes?.[menu?.menuPayments?.paymentRequirementTypeId];
    if (pt?.enableCancellation) {
      const m1 = moment(String(booking.date), "YYYYMMDD").add(booking.intervalId / (60 * 1000), "minutes");
      const m2 = moment(booking.cancellationDate);
      const numDays = Math.ceil(m1.diff(m2, "days", true));
      // const numAdults = Number(pax) - Number(children || 0);
      // const numChildren = Number(children || 0);
      for (let i = 0; i < pt.timeRules.length; i++) {
        const tr = pt.timeRules[i];
        if (numDays >= tr.from && numDays <= tr.to) {
          if (pt.feeTypeId === 3) {
            // $
            adultFee = tr.adultAmount * numAdults;
            childFee = tr.childAmount * numChildren;
            break;
          } else if (pt.feeTypeId === 1 || pt.feeTypeId === 2) {
            // %
            let product = find(products, (p) => p.id === booking.menuOptionId);
            if (product) {
              const price = Number(product.restaurants[booking.restaurantId].price[product.fixedPriceMenuTypes.adult]);
              adultFee = (tr.adultAmount / 100) * price;
              for (let j = 0; j < (booking.guests || []).length; j++) {
                const guest = booking.guests[j];
                product = find(products, (p) => p.id === guest.menuOptionId);
                if (product) {
                  if (!guest.childOrDependent) {
                    adultFee += (tr.adultAmount / 100) * Number(product.restaurants[booking.restaurantId].price[product.fixedPriceMenuTypes.adult] as number);
                  } else {
                    childFee += (tr.childAmount / 100) * Number(product.restaurants[booking.restaurantId].price[product.fixedPriceMenuTypes.adult] as number);
                  }
                }
              }
              const func = pt.roundingTypeId === 1 ? Math.ceil : Math.floor;
              if (pt.nearestTypeId === 1) {
                // nearest $1
                adultFee = func(adultFee / 1) * 1;
                childFee = func(childFee / 1) * 1;
              } else if (pt.nearestTypeId === 2) {
                // nearest $5
                adultFee = func(adultFee / 5) * 5;
                childFee = func(childFee / 5) * 5;
              } else if (pt.nearestTypeId === 3) {
                // nearest $10
                adultFee = func(adultFee / 10) * 10;
                childFee = func(childFee / 10) * 10;
              }
            }
          }
        }
      }
    }
  }
  return { adultFee: +adultFee.toFixed(2), childFee: +childFee.toFixed(2) };
};

export function getEnd(booking: Booking | FunctionBooking) {
  return Number(booking.intervalId) + Number(booking.alg.duration) * 60 * 1000;
}

export function getBookingsBetweenTwoTimes(sectionId, boxId, bookingToAdd: Booking | FunctionBooking, seatings: Seatings, courses: Course[], defaultMenus, bookings: (Booking | FunctionBooking)[], style: Style) {
  if (!bookingToAdd) return [];
  const bookings1 = JSON.parse(JSON.stringify(bookings)) as (Booking | FunctionBooking)[];
  const seatingId = getSeatingId(seatings, bookingToAdd.intervalId);
  const seating = seatings[seatingId] || ({} as Seating);
  const courseIndex = _.findIndex(courses, (c) => "courseId" in bookingToAdd && c._key === bookingToAdd.courseId);
  const course = courseIndex > -1 ? courses[courseIndex] : null;
  const duration1 = getDuration(bookingToAdd, seating, course, defaultMenus, style);
  const _date1 = Number(bookingToAdd.intervalId);
  const end1 = _date1 + duration1 * 60 * 1000;
  return _.filter(
    bookings1,
    (b) => b._key !== bookingToAdd._key && b.alg.tables && (!sectionId || b.alg.sectionId === sectionId || (b.alg.proximity !== undefined && _.findIndex(b.alg.proximity, (p) => p.sectionId === sectionId) > -1)) && (!boxId || b.alg.tables[boxId] || (b.alg.proximity !== undefined && _.findIndex(b.alg.proximity, (p) => p.tables[boxId] !== undefined) > -1)) && ((Number(b.intervalId) <= _date1 && getEnd(b) > _date1) || (Number(b.intervalId) < end1 && getEnd(b) >= end1) || (Number(b.intervalId) >= _date1 && getEnd(b) >= _date1 && Number(b.intervalId) <= end1 && getEnd(b) <= end1))
  );
}

export function getPaymentsFromBooking(booking: Booking | FunctionBooking, productSizes, butlerServiceGroups = []) {
  const preorderLineItems = [];
  const requiredLineItems = [];
  const prepaidLineItems = [];
  let totalPreorder1 = 0;
  let totalRequired1 = 0;
  let totalPrepaid1 = 0;
  let serviceFee = 0;
  let surcharges = [];
  const serviceCharges = [];
  let salesTax = 0;
  if (!("functionName" in booking) && booking?.paymentSummary) {
    if (booking.paymentSummary.lineItems) {
      for (let i = 0; i < booking.paymentSummary.lineItems.length; i++) {
        const { id1, name, description, price, quantity, total, lineItemTypeId } = booking.paymentSummary.lineItems[i];
        if ((booking.paymentSummary.requiredPaymentTypeId !== 1 || !booking.ccOverride) && (lineItemTypeId === LineItemTypeId.Deposit || lineItemTypeId === LineItemTypeId.Menu || lineItemTypeId === LineItemTypeId.Product)) {
          requiredLineItems.push({
            id: id1,
            name,
            description,
            quantity,
            type: lineItemTypeId,
            price: price.toFixed(2) ? `$${price}` : "",
            total: total.toFixed(2) ? `$${total}` : "",
          });
          if (total) {
            totalRequired1 += Number(total);
          }
        }
      }
    }
  }
  if (!("functionName" in booking) && booking?.categories) {
    for (let i = 0; i < booking.categories.length; i++) {
      const category = booking.categories[i];
      const butlerProduct = findCategorizedProductById(butlerServiceGroups, category.productId);
      if (butlerProduct) {
        const productSize = productSizes[category.productSizeId];
        let price = butlerProduct.price ? Number(butlerProduct.price) : 0;
        const butlerServiceCategory = _.find(butlerServiceGroups, (bsc) => bsc._key === butlerProduct.categoryId);
        const butlerServiceCategoryName = butlerServiceCategory ? butlerServiceCategory.category : "";
        price = +price.toFixed(2);
        let total = price * Number(category.quantity);
        total = +total.toFixed(2);
        if (!butlerProduct.requiredPrepayment) {
          preorderLineItems.push({
            id: category.productSizeId,
            name: `${butlerProduct.productName}${productSize ? ` - ${productSize.name}` : ""}`,
            description: "",
            category: butlerServiceCategoryName,
            price: `$${price}`,
            quantity: category.quantity,
            type: 1,
            total: `$${total}`,
          });
          totalPreorder1 += price * Number(category.quantity);
        } else {
          requiredLineItems.push({
            id: category.productSizeId,
            name: `${butlerProduct.productName}${productSize ? ` - ${productSize.name}` : ""}`,
            description: "",
            quantity: category.quantity,
            category: butlerServiceCategoryName,
            type: 1,
            price: `$${price}`,
            total: price * Number(category.quantity),
          });
          totalRequired1 += Number(price * Number(category.quantity));
          // totalRequired = totalRequired1.toFixed(2);
        }
      }
    }
  }
  if (booking.paymentSummary?.lineItems) {
    for (let j = 0; j < booking.paymentSummary.lineItems.length; j++) {
      const li = booking.paymentSummary.lineItems[j];
      let total = 0;
      let price = 0;
      if (li.total) {
        total = li.total;
      }
      if (li.salesTax) {
        salesTax += Number(li.salesTax);
      }
      if (li.price) {
        price = li.price;
      }
      total = +Number(total).toFixed(2);
      price = +Number(price).toFixed(2);
      prepaidLineItems.push({
        id: li.id1,
        name: li.name,
        description: li.description,
        text: li.text,
        price,
        quantity: li.quantity,
        type: li.lineItemTypeId,
        total,
      });
    }
    if (booking.paymentSummary.serviceFeeAmount) {
      serviceFee += Number(booking.paymentSummary.serviceFeeAmount);
    }
    // if (booking.paymentSummary..surcharges) {
    //   surcharges = [...surcharges, ...transaction.surcharges];
    // }
    if (booking.paymentSummary.serviceCharge) {
      serviceCharges.push(booking.paymentSummary.serviceCharge);
    }
    totalPrepaid1 = Number(booking.paymentSummary.grandTotal);
  }

  return [
    { items: preorderLineItems, total: `$${totalPreorder1.toFixed(2)}` },
    { items: prepaidLineItems, total: `$${totalPrepaid1.toFixed(2)}`, serviceFee: serviceFee.toFixed(2), surcharges, serviceCharges, salesTax: salesTax.toFixed(2) },
    { items: requiredLineItems, total: `$${totalRequired1.toFixed(2)}` },
  ];
}

function getVisibleSubcategories(subcategories, order, widgetSubcategories = null) {
  const subcategories1 = _.chain(subcategories)
    .pickBy((_undefined, key) => !widgetSubcategories || (widgetSubcategories[key] && widgetSubcategories[key].enabled))
    .map((value, key) => ({ ...value, _key: key }))
    .value();
  return _.sortBy(subcategories1, (g) => order[g._key]);
}

function getVisibleCategories(categories, order) {
  let categories1 = _.pickBy(categories, (category) => category.enabled === true);
  categories1 = _.map(categories1, (value, key) => ({ ...value, _key: key }));
  return _.sortBy(categories1, (value) => order[value._key]);
}

function getVisibleProducts(products, order) {
  let products1 = _.pickBy(products, (product) => product.enabled === true);
  products1 = _.map(products1, (value, key) => ({ ...value, _key: key }));
  return _.sortBy(products1, (value) => order[value._key]);
}

export function isPastService(now, date, openingTimes) {
  const now1 = momentTz(now);
  if (Number(date) < Number(now1.format("YYYYMMDD"))) {
    return true;
  } else if (Number(date) === Number(now1.format("YYYYMMDD"))) {
    if (openingTimes && openingTimes.close && openingTimes.close > openingTimes.open) {
      const ticks = now1.valueOf() - now1.startOf("day").valueOf();
      return ticks > openingTimes.close;
    }
  } else if (Number(date) + 1 === Number(now1.format("YYYYMMDD"))) {
    if (openingTimes && openingTimes.close && openingTimes.close < openingTimes.open) {
      const ticks = now1.valueOf() - now1.startOf("day").valueOf();
      return ticks > openingTimes.close;
    }
  }
  return false;
}

export function getRule(rules, groupAreaId: string) {
  const ruleId = _.findKey(rules, (rule) => rule.groupAreaId === groupAreaId);
  if (ruleId && rules[ruleId]) {
    return rules[ruleId];
  }
  return {};
}

export function isLocked(openingTimes: OpeningTimes, areaGroups: AreaGroup[], rules, groupAreaId, now: Moment, date: number, forceUnlocked = false) {
  if (forceUnlocked) return false;
  if (Number(now.format("YYYYMMDD")) > Number(date)) return true;
  const rule = getRule(rules, groupAreaId);
  const isToday = Number(now.format("YYYYMMDD")) === Number(date);
  let lockBookings = 0;
  if (rule.lockBookings && rule.lockBookingsValue) {
    lockBookings = rule.lockBookingsValue;
  }
  return isToday && openingTimes.open - lockBookings * 3600 * 1000 < 1000 * (3600 * now.hours() + 60 * now.minutes());
}

export function getOpeningTimesAdjusted(open: number, close: number): { open: number; close: number } {
  let close1 = close;
  if (close1 < open) {
    close1 = 24 * 60 * 60 * 1000 + close1;
  }
  return { open, close: close1 };
}

export function getButlerServiceGroups(butlerServiceCategories, products: Products, order, subcategories = null) {
  const butlerServiceCategories1 = getVisibleCategories(butlerServiceCategories, order);
  const groups = [];
  let index = 0;
  for (let i = 0; i < butlerServiceCategories1.length; i++) {
    const subcategories1 = getVisibleSubcategories(butlerServiceCategories1[i].subcategories, order, subcategories);
    if (subcategories1.length > 0) {
      groups.push({ _key: butlerServiceCategories1[i]._key, category: butlerServiceCategories1[i].name, subcategories: [], rank: order[butlerServiceCategories1[i]._key] });
      for (let j = 0; j < subcategories1.length; j++) {
        const products1 = {};
        for (const productId in products) {
          const subId = _.findKey(products[productId].butlerService, (butlerServiceItem: any) => butlerServiceItem.subcategoryId === subcategories1[j]._key);
          if (subId) {
            Object.assign(products1, { [productId]: products[productId] });
          }
        }
        if (Object.keys(products1).length > 0) {
          groups[index].subcategories.push({
            _key: subcategories1[j]._key,
            name: butlerServiceCategories1[i].subcategories[subcategories1[j]._key].name,
            rank: order[subcategories1[j]._key],
            products: getVisibleProducts(products1, order),
          });
        }
      }
      index++;
    }
  }
  return groups;
}

export function prepaymentCategories(categories, products: Products) {
  const results = [];
  if (categories) {
    for (let i = 0; i < categories.length; i++) {
      const cat = categories[i];
      if (cat && cat.productId && products[cat.productId]) {
        if (products[cat.productId].butlerService) {
          if (cat.productSizeId && products[cat.productId].butlerService[cat.productSizeId] && products[cat.productId].butlerService[cat.productSizeId].requiredPrepayment) {
            results.push(cat);
          }
        }
      }
    }
  }
  return results;
}

export function checkBookingGuestSpace(booking, bookingCustomerEmail = null, emailsToCheck = []) {
  const guestPax = Number(booking.pax) - 1;
  const totalAdultGuest = guestPax - Number(booking.children || 0);
  const filledAdultGuest = (booking.guests || []).filter((g) => g.customerId && g.isAttending);
  const isAdultGuestSpaceAvailable = totalAdultGuest - filledAdultGuest.length > 0;
  const filledGuest = (booking.guests || []).filter((g) => g.isAttending);
  const isGuestSpaceAvailable = totalAdultGuest - filledAdultGuest.length > 0;
  let existingCustomerId = null;
  if (bookingCustomerEmail && emailsToCheck.includes(bookingCustomerEmail)) existingCustomerId = booking.customerId;
  emailsToCheck.forEach((email) => {
    const findGuest = filledAdultGuest.find((g) => g.customer.email === email);
    if (findGuest) {
      existingCustomerId = findGuest.customerId;
      return;
    }
  });

  return { totalAdultGuest, filledAdultGuest, isAdultGuestSpaceAvailable, totalGuest: guestPax, filledGuest, isGuestSpaceAvailable, existingCustomerId };
}

export function getGuestsPrefilled(booking: Booking, menuOptionId: string) {
  // if (booking.casual) return [];
  const totalGuests = Math.max(Number(booking.pax) - 1 + Number(booking.extraPax || 0), filter(booking.guests, (g) => g.customerId)?.length);
  let guests: Guest[] = Array.from({ length: totalGuests }).map((_undefined, i) => ({
    customerId: "",
    customer: null,
    childOrDependent: false,
    menuOptionId,
    ...booking.guests?.[i],
  }));
  guests = _.orderBy(guests, (g) => g.childOrDependent, "asc");
  const children = Number(booking.children || 0);
  for (let i = 0; i < guests.length; i++) {
    if (!guests[i].customer && i >= totalGuests - children) {
      guests[i].childOrDependent = true;
    } else if (!guests[i].customer) {
      guests[i].childOrDependent = false;
    }
  }
  return guests;
}

export function getBookingExpiryTime(zoneId, booking) {
  const diffInTimeZone = momentTz().tz(zoneId).utcOffset() * 60;
  const startOfDay = momentTz.tz(booking.date, "YYYYMMDD", zoneId).unix() + diffInTimeZone;
  const endTime = booking.intervalId / 1000 + (booking.alg.duration || 0) * 60 - Number(booking.alg.reset || 0) * 60;
  const bookingExpiryTime = (startOfDay + endTime) * 1000 + 5 * 60 * 60 * 1000;
  return bookingExpiryTime;
}

export function getTabTransactionsData(tab: PaymentTab) {
  if (!tab) return { paidWithTabTransactions: [], totalTabAmount: 0, totalPaidAmount: 0 };
  const totalTabAmount = tab.amount;
  const paidWithTabTransactions = values(tab.tabTransactions);
  const totalPaidAmount = tab.amount - tab.remainingAmount;
  return { paidWithTabTransactions, totalTabAmount, totalPaidAmount };
}

export const getAvailableTabPayments = (tabs: PaymentTab[], customerId): AvailableTabPayment[] => {
  const ownedTabs: PaymentTab[] = tabs.filter((tab) => !tab.closed && tab.tabOwner === customerId);
  const associatedTabs: PaymentTab[] = tabs.filter((tab) => !tab.closed && (tab?.guests?.[customerId] || (tab?.allowAllGuests && tab.tabOwner !== customerId)));
  const availableTabPayments: AvailableTabPayment[] = [];
  ownedTabs.forEach((tab) => {
    const { totalTabAmount, totalPaidAmount, paidWithTabTransactions } = getTabTransactionsData(tab);
    const tabAvailableAmount = totalTabAmount - totalPaidAmount;
    const customerLimit = tab.tabOwnerLimit;
    const customerPaidAmount = paidWithTabTransactions.reduce((accumulator, transaction) => {
      const filterPayments = transaction.payments.filter((p) => (p.paymentTypeId === PaymentTypeId.Deposit || p.paymentTypeId === PaymentTypeId.Tab) && p.customerId === customerId);
      const totalPaymentByTab = filterPayments.reduce((acc, val) => acc + val.value, 0);
      return accumulator + totalPaymentByTab;
    }, 0);
    if (!customerLimit) availableTabPayments.push({ tab, limit: null, availableAmount: tabAvailableAmount, paidAmount: customerPaidAmount });
    else {
      const remainingAmount = customerLimit <= customerPaidAmount ? 0 : customerLimit - customerPaidAmount;
      const availableAmount = remainingAmount <= tabAvailableAmount ? remainingAmount : tabAvailableAmount;
      availableTabPayments.push({ tab, limit: customerLimit, availableAmount, paidAmount: customerPaidAmount });
    }
  });
  associatedTabs.forEach((tab) => {
    const { totalTabAmount, totalPaidAmount, paidWithTabTransactions } = getTabTransactionsData(tab);
    const tabAvailableAmount = totalTabAmount - totalPaidAmount;
    const customerLimit = tab.guests?.[customerId]?.tabLimit || (tab.allowAllGuests ? (tab.perGuestLimit ? tab.perGuestLimit : tab.remainingAmount) : null);
    const customerPaidAmount = paidWithTabTransactions.reduce((accumulator, transaction) => {
      const filterPayments = transaction.payments.filter((p) => (p.paymentTypeId === PaymentTypeId.Deposit || p.paymentTypeId === PaymentTypeId.Tab) && p.customerId === customerId);
      const totalPaymentByTab = filterPayments.reduce((acc, val) => acc + val.value, 0);
      return accumulator + totalPaymentByTab;
    }, 0);
    if (!customerLimit) availableTabPayments.push({ tab, limit: null, availableAmount: tabAvailableAmount, paidAmount: customerPaidAmount });
    else {
      const remainingAmount = customerLimit <= customerPaidAmount ? 0 : customerLimit - customerPaidAmount;
      const availableAmount = remainingAmount <= tabAvailableAmount ? remainingAmount : tabAvailableAmount;
      availableTabPayments.push({ tab, limit: customerLimit, availableAmount, paidAmount: customerPaidAmount });
    }
  });
  return availableTabPayments;
};

export const checkOrderItemExistsInDrinks = (menu: Menu, headingId) => {
  return _.has(menu?.beverage?.groupHeadings || {}, headingId) || Object.values(menu?.drinkInclusions || {}).some(({ groupHeadings }) => _.has(groupHeadings || {}, headingId));
};

export const checkOrderItemExistsInFoods = (menu: Menu, headingId) => {
  return _.has(menu?.food?.groupHeadings || {}, headingId) || Object.values(menu?.foodInclusions || {}).some(({ groupHeadings }) => _.has(groupHeadings || {}, headingId));
};

export const getOrderingAppLink = (widgetPath, date, restaurantId, mealId, bookingId, clientId, guestUserId = null) => {
  const user = guestUserId ? `&userId=${guestUserId}` : "";
  return `${widgetPath}/ordering/welcome?date=${date}&restaurantId=${restaurantId}&mealId=${mealId}&bookingId=${bookingId}&clientId=${clientId}${user}`;
};

export const getBillAvailableAndUnpaidCustomerId = (payLaters: PayLaters, bookingId: string): [boolean, { [bookingId: string]: string[] }] => {
  const unpaidBookingCustomerIds: { [bookingId: string]: string[] } = {};
  const filteredPayLaters = bookingId ? pickBy(payLaters, (pl) => pl.bookingId === bookingId) : payLaters;
  for (const pl in filteredPayLaters) {
    unpaidBookingCustomerIds[filteredPayLaters[pl].bookingId] = unpaidBookingCustomerIds[filteredPayLaters[pl].bookingId] || [];
    unpaidBookingCustomerIds[filteredPayLaters[pl].bookingId].push(filteredPayLaters[pl].customerId);
  }
  return [keys(filteredPayLaters).length > 0, unpaidBookingCustomerIds];
};

export const getNextCourseOrderIndex = (sortedFixedOrderBatchSettings: BatchSetting[], sentOrderItems: OrderItem[], bookingBuckets: BatchingBuckets) => {
  let nextCourseIndex = -1;
  const courseGroups: CourseGroup[] = [];
  const allPendingBuckets: Bucket[] = [];
  const buckets = _.filter(bookingBuckets, (bucket) => !bucket.isCasual);
  let preparedCourseFound = false;
  sortedFixedOrderBatchSettings.forEach((batchSetting) => {
    const sortedCourseGroups: CourseGroup[] = _.orderBy(batchSetting.courseGroups, ["order"], ["asc"]);
    const isBatchItemSent: boolean = sentOrderItems.find((soi) => batchSetting.menuHeadingIds.includes(soi.headingId)) !== undefined;
    const pendingBucket: Bucket = buckets.find((bucket) => bucket.batchSettingId === batchSetting.id && !bucket.completed);
    if (pendingBucket) allPendingBuckets.push(pendingBucket);
    const courseGroupsLength: number = courseGroups.length;
    courseGroups.push(...sortedCourseGroups.map((cg) => ({ ...cg, batchSettingId: batchSetting.id })));
    if (isBatchItemSent) {
      for (let i = courseGroupsLength; i < courseGroups.length; i++) {
        const courseGroup = courseGroups[i];
        const isCourseItem = sentOrderItems.find((soi) => courseGroup.menuHeadingIds.includes(soi.headingId)) !== undefined;
        if (isCourseItem) {
          if (preparedCourseFound) {
            nextCourseIndex = i;
            preparedCourseFound = false;
          }
          const sentCourseItems = sentOrderItems.filter((aoi) => courseGroup.menuHeadingIds.includes(aoi.headingId));
          const allItemsPrepared = sentCourseItems.every((oi) => oi.orderStatus >= OrderItemStatus.Prepared);
          if (allItemsPrepared) {
            preparedCourseFound = true;
            nextCourseIndex = -1;
          }
        }
      }
    }
  });

  const previousBatchSettingIds: Array<string> = [];
  for (let i = 0; i < nextCourseIndex; i++) courseGroups.length > 0 && previousBatchSettingIds.push(courseGroups[i].batchSettingId);
  const previousPendingBuckets: Bucket[] = allPendingBuckets.filter((bucket) => previousBatchSettingIds.includes(bucket.batchSettingId));
  return [nextCourseIndex, courseGroups, previousPendingBuckets] as [number, CourseGroup[], Bucket[]];
};

export const GetServiceAmount = (totalAmount: number, paymentTypeId: PaymentTypeId, fees) => {
  const { serviceFee = 0 } = fees || {};
  return paymentTypeId === PaymentTypeId.Eftpos || paymentTypeId === PaymentTypeId.Offline || paymentTypeId === PaymentTypeId.Stripe ? +((serviceFee / 100) * totalAmount).toFixed(2) : 0;
};

export function buildDataFromDocs<T>(docs): T {
  const data = {} as T;
  docs.forEach((doc) => {
    data[doc.id] = doc.data();
  });
  return data;
}

export function buildDataFromCollection<T>(collectionData): T {
  let docData = {} as T;
  if (collectionData.empty) return docData;
  collectionData.docs.forEach((doc) => {
    docData = Object.assign(docData, { [doc.id]: doc.data() });
  });
  return docData;
}

export function buildArrayFromDocs<T>(docs) {
  const data: T[] = [];
  docs.forEach((doc) => {
    data.push({ id: doc.id, ...doc.data() } as T);
  });
  return data;
}

export function getShardsFromMaestro(m: Maestro | MaestroDate): string[] {
  // Convert data to Buffer if it's a string
  const jsonString = typeof m === "string" ? m : JSON.stringify(m);

  const characters = jsonString.split("");
  const resultArray: string[] = [];
  const numShards = 20 * 1024; // 20KB per shard

  for (let i = 0; i < characters.length; i += numShards) {
    resultArray.push(characters.slice(i, i + numShards).join(""));
  }
  return resultArray;
}

export function getMaestroFromShards<T extends Maestro | MaestroDate>(shards: { data: string; index: number }[]): T {
  if (shards.length < 1) {
    return null; // Invalid input
  }

  return JSON.parse(
    chain(shards)
      .orderBy((s) => s.index)
      .map((s) => s.data)
      .value()
      .join("")
  );
}

export function findGroupHeadingByMenu(menu: Menu, menuOptionId: string, targetId: string): GroupHeading | null {
  const groupHeadings: GroupHeadings = assign({}, menu.food?.groupHeadings, menu.beverage?.groupHeadings, menu.drinkInclusions?.[menuOptionId]?.groupHeadings, menu.foodInclusions?.[menuOptionId]?.groupHeadings);
  return findGroupHeadingById(groupHeadings, targetId);
}

export function findGroupHeadingById(groupHeadings: GroupHeadings, targetId: string): GroupHeading | null {
  if (!groupHeadings) return null;
  if (groupHeadings[targetId]) {
    return groupHeadings[targetId];
  } else {
    for (const key in groupHeadings) {
      const childGroup = groupHeadings[key];
      const result = findGroupHeadingById(childGroup.groupHeadings, targetId);
      if (result) {
        return result;
      }
    }
    return null;
  }
}
export const getAttendingGuestsOfBooking = (booking: Booking): Guest[] => {
  if (!booking) return [];
  let guestsOfBooking = [];
  if (booking.guests) {
    guestsOfBooking = booking.guests.filter((g) => g.isAttending && g.customerId);
  }
  return guestsOfBooking;
};

export function getCustomerNameByNameDisplayConfig(restaurant: Restaurant, customer: Customer | Partial<Customer>) {
  if (customer?.firstName && customer?.lastName) {
    if (restaurant.customerNameDisplay === "firstName") {
      return `${customer.firstName} ${customer.lastName}`;
    }
    return `${customer.lastName}, ${customer.firstName}`;
  }
  return "";
}

export function getCustomerOrGuestDisplayName(customerId: string, restaurant: Restaurant, linkedBooking: Booking, customers: Customers) {
  if (!customerId || !linkedBooking) return "";
  const guestsOfBooking = getAttendingGuestsOfBooking(linkedBooking);
  const guest = guestsOfBooking.find((g) => g.customerId === customerId);

  const name = getCustomerNameByNameDisplayConfig(restaurant, guest?.customer || customers[customerId]);
  const seat = guest?.seat || linkedBooking?.organizerSeat;

  const position = seat ? `Position : ${seat} ${name ? ", " : ""}` : "";
  return `${position} ${name}`;
}

/**
 * Checks if given Menu contains any one of PaymentRequirementTypes
 * @return boolean
 */
export function hasPaymentType(menu: Menu, paymentTypes: PaymentRequirementTypes[]): boolean {
  if (!menu) return false;
  return paymentTypes.includes(menu.menuPayments.paymentRequirementTypeId);
}

/**
 *
 * @param value value in number or string
 * @return boolean which represents whether it can be valid number or not
 */
export function canThisBeNumber(value: number | string): boolean {
  const valueToNumber = value || value === "0" || value === 0 ? Number(value) : null;
  if (valueToNumber || valueToNumber === 0) return true;
  return false;
}

export const getNowByZoneId = (zoneId: string) => {
  const now = momentTz.tz(zoneId);
  return (now.hour() * 3600 + now.minute() * 60) * 1000;
};

export const getTodayByZoneId = (zoneId: string): number => {
  return Number(moment.tz(moment(), zoneId).format("YYYYMMDD"));
};

export function arePhoneNumbersEqual(phoneNumber1: string, phoneNumber2: string): boolean {
  const strippedNumber1 = phoneNumber1.replace(/^\+/, "");
  const strippedNumber2 = phoneNumber2.replace(/^\+/, "");
  return strippedNumber1 === strippedNumber2;
}

export function doesPhoneNumberExist(phoneNumbers: string[], numberToCheck: string): boolean {
  return _.some(phoneNumbers, (phoneNumber) => {
    return arePhoneNumbersEqual(phoneNumber, numberToCheck) || phoneNumber === numberToCheck;
  });
}

/**
 * Picks unique item based on given path and picks the one with max value between them
 * Use case: get the recently used unique items
 */
export function uniqueAndRecentBy<T>(list: T[], groupPath: string, maxBy: string): T[] {
  return _.chain(list)
    .groupBy(groupPath)
    .mapValues((item) => _.maxBy(item, maxBy))
    .values()
    .value();
}

/**
 * Filters given list of order by bookingId, mealId, customterId
 */
// const filterOrderByBookingId = (payLaters: PayLaters, bookingId?: string, customerId?: string): PayLaters => {
//   if (bookingId && customerId) {
//     return pickBy(payLaters, (pl) => pl.bookingId === bookingId && pl.customerId === customerId);
//   } else if (bookingId && !customerId) {
//     return pickBy(payLaters, (pl) => pl.bookingId === bookingId);
//   } else if (!bookingId) {
//     return pickBy(payLaters, (pl) => pl.customerId === customerId);
//   }
//   return payLaters;
// };

/**
 * Iterates over given list of orders and returns matches based on given predicate on OrderItem
 */
// const getFilteredOrderStatus = (orders: _.Dictionary<Order>, predicate: (oi: OrderItem) => boolean, bookingId?: string, mealId?: string, customerId?: string) => {
//   let hasMatchedOrders = false;
//   const matchedBookingIds: { [bookingId: string]: string[] } = {};

//   _.forEach(orders, (o) => {
//     _.forEach(o.orderItems, (oi) => {
//       if (predicate(oi)) {
//         if (!matchedBookingIds[o.bookingId]) matchedBookingIds[o.bookingId] = [];
//         hasMatchedOrders = true;
//         if (!matchedBookingIds[o.bookingId].includes(o.customerId)) matchedBookingIds[o.bookingId].push(o.customerId);
//       }
//     });
//   });

//   return { hasMatchedOrders, matchedBookingIds };
// };

/**
 * Return status of Pay with cash now orders
 */
export const getPayWithCashNowStatus = (payLaters: PayLaters, bookingId = "", customerId = ""): [boolean, { [bookingId: string]: string[] }] => {
  let filteredPayNow: PayLaters = {};
  if (bookingId && customerId) {
    filteredPayNow = pickBy(payLaters, (pl) => pl.bookingId === bookingId && pl.customerId === customerId && findKey(pl.orderItems, (oi) => oi.payNow) !== undefined);
  } else if (bookingId && !customerId) {
    filteredPayNow = pickBy(payLaters, (pl) => pl.bookingId === bookingId && findKey(pl.orderItems, (oi) => oi.payNow) !== undefined);
  } else {
    filteredPayNow = pickBy(payLaters, (pl) => findKey(pl.orderItems, (oi) => oi.payNow) !== undefined);
  }
  const matchedBookingIds: { [bookingId: string]: string[] } = {};
  for (const pl in filteredPayNow) {
    matchedBookingIds[filteredPayNow[pl].bookingId] = matchedBookingIds[filteredPayNow[pl].bookingId] || [];
    matchedBookingIds[filteredPayNow[pl].bookingId].push(filteredPayNow[pl].customerId);
  }
  return [keys(matchedBookingIds).length > 0, matchedBookingIds];
};

/**
 * Return status of Bills (Pay Later orders) to be paid now
 *
 */
export const getPayBillNowStatus = (payLaters: PayLaters, bookingId = "", customerId = ""): [boolean, { [bookingId: string]: string[] }] => {
  let filteredPayNow: PayLaters = {};
  if (bookingId && customerId) {
    filteredPayNow = pickBy(payLaters, (pl) => pl.bookingId === bookingId && pl.customerId === customerId && findKey(pl.orderItems, (oi) => oi.payNow && oi.payLater) !== undefined);
  } else if (bookingId && !customerId) {
    filteredPayNow = pickBy(payLaters, (pl) => pl.bookingId === bookingId && findKey(pl.orderItems, (oi) => oi.payNow && oi.payLater) !== undefined);
  } else {
    filteredPayNow = pickBy(payLaters, (pl) => findKey(pl.orderItems, (oi) => oi.payNow && oi.payLater) !== undefined);
  }
  const matchedBookingIds: { [bookingId: string]: string[] } = {};
  for (const pl in filteredPayNow) {
    matchedBookingIds[filteredPayNow[pl].bookingId] = matchedBookingIds[filteredPayNow[pl].bookingId] || [];
    matchedBookingIds[filteredPayNow[pl].bookingId].push(filteredPayNow[pl].customerId);
  }
  return [keys(matchedBookingIds).length > 0, matchedBookingIds];
};

// a simple implementation of the shallowCompare.
// only compares the first level properties and hence shallow.
// state updates(theoretically) if this function returns true.
export function shallowCompare(newObj: any, prevObj: any) {
  for (const key in newObj) {
    if (newObj[key] !== prevObj[key]) return true;
  }
  return false;
}

/**
 * Returns status of future dates for given start date and optional end date in date label
 * End date defaults to end of the month if not specified
 * @param {Label} label Date label
 * @param {string} zoneId zoneId for date/ time calculation. usually restaurant's zone id, eg "Sydney/ Australia"
 * @param {string | number} startDate start date value
 * @param {string | number} endtDate optional end date value
 */
export function getDateStatusByDateRange(label: Label, zoneId: string, startDate: string | number, endDate?: string | number) {
  const currentDate = momentTz.tz(startDate, zoneId);
  const maxDate = momentTz.tz(endDate, zoneId) || momentTz.tz(startDate, zoneId).endOf("month");

  const dateStatus: { [date: number]: boolean } = {};

  while (currentDate.isSameOrBefore(maxDate, "day")) {
    dateStatus[Number(currentDate.format("YYYYMMDD"))] = isClosed(label, zoneId, currentDate);
    currentDate.add(1, "day");
  }
  return dateStatus;
}

export function isValidPhoneNumberForCountry(phoneNumberString, country) {
  const phoneNumber = parsePhoneNumber(phoneNumberString, {
    defaultCountry: country,
    extract: false,
  } as any);
  if (!phoneNumber) {
    return false;
  }
  if (phoneNumber.country !== country) {
    return false;
  }
  return phoneNumber.isValid();
}

import TreeSelect, { SHOW_PARENT } from 'rc-tree-select';
import { useEffect, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Table } from 'reactstrap';
import { doesMenuHaveProduct } from '../../../../resbutler-utils/utils/menuUtils';
import STOCK_CONSTANT from '../../constant';
import SpecialForm from '../special/SpecialForm';
import ConditionalRender from './ConditionalRender';

interface IProductFormProps {
  stockLimitKey: string;
  errors?: any;
  productId?: any;
  menus?: any;
  size?: any;
  product?: any;
  restaurantMenuHeadings?: any;
  updateProducts?: any;
  restaurantId?: string;
}

export default function ProductForm({ stockLimitKey, errors = {}, productId, menus, size, product, restaurantMenuHeadings, updateProducts, restaurantId }: IProductFormProps) {
  const [menuHeadings, setMenuHeadings] = useState<any>([]);
  const values = useFormState().values;
  const form = useForm();

  useEffect(() => {
    const headingTree = [];
    Object.entries(menus).map(([menuId, menu]: any) => {
      const doesMenuHaveProduct1 = doesMenuHaveProduct(productId, menu);
      if (doesMenuHaveProduct1) {
        headingTree.push({
          key: menuId,
          title: menu.name,
          value: menuId,
          disabled: false,
        });
      }
    });

    setMenuHeadings(headingTree);
  }, [menus]);

  /**
   * @description only show those menus which are selected by special form at first of each product size
   * @param {Array<string>} menuIds
   */
  function handleDependentMenuIds(menuIds) {
    const menuHeading = Object.entries(menus)
      .map(([menuId, menu]: any) => {
        if (menuIds.indexOf(menuId) !== -1) {
          return {
            key: menuId,
            title: menu.name,
            value: menuId,
            disabled: false,
          };
        }
      })
      .filter((item) => item !== undefined);

    // update specific menu Ids
    setMenuHeadings(menuHeading);
  }

  /**
   * Handle menu selection tree
   * @description based on selected menu ids of product's, will reevaluate
   * required operation for stock number
   *
   * @param  {string} ids product's menu unique Ids
   * @param  {object} stocks form state values
   * @param  {string} productId product's unique Id
   * @param  {string} sizeId product's size Ids
   */
  function handleMenuStock(menuIds, stocks, productId, sizeId) {
    const menus = stocks[stockLimitKey]?.[productId]?.[sizeId].stock?.menus || {};

    if (Object.keys(menus).length) {
      // new menu is selected
      if (menuIds.length > Object.keys(menus).length) {
        let menuStocks = { ...menus };
        const newMenuIds = menuIds.filter((n) => !Object.keys(menus).includes(n));

        newMenuIds.forEach((id) => (menuStocks[id] = 0));
        form.change(`${stockLimitKey}.${productId}.${sizeId}.stock.menus`, menuStocks);
      } else {
        if (!menuIds.length) {
          form.change(`${stockLimitKey}.${productId}.${sizeId}.stock.menus`, {});
        } else {
          const menuStocks = { ...menus };

          // get unchecked menus ids
          const uncheckedMenuIds = Object.keys(menus).filter((n) => !menuIds.includes(n));

          uncheckedMenuIds.forEach((menuId) => delete menuStocks[menuId]);

          form.change(`${stockLimitKey}.${productId}.${sizeId}.stock.menus`, menuStocks);
        }
      }
    } else {
      let menuStocks = {};
      menuIds.forEach((id) => (menuStocks[id] = 0));
      form.change(`${stockLimitKey}.${productId}.${sizeId}.stock.menus`, menuStocks);
    }
  }

  /**
   * Reset previous values of selected stock limit
   *
   * @param  {string} type type of stock limit
   * @param  {object} form react final form form's api
   * @param  {string} name input name
   */
  function handleStockChange(type, name) {
    switch (type) {
      case STOCK_CONSTANT.appliedTo.TOTAL: {
        form.change(name + '.stock.menus', {});
        form.change(name + '.stock.dineIn', null);
        form.change(name + '.stock.takeaway', null);

        break;
      }

      case STOCK_CONSTANT.appliedTo.MENUS: {
        form.change(name + '.stock.current', null);
        form.change(name + '.stock.dineIn', null);
        form.change(name + '.stock.takeaway', null);

        break;
      }

      case STOCK_CONSTANT.appliedTo.DINE_IN_TAKEAWAY: {
        form.change(name + '.stock.current', null);
        form.change(name + '.stock.menus', {});

        break;
      }
    }
  }

  return (
    <Card>
      <CardBody className="mt-2 border rounded p-3">
        {stockLimitKey === 'specials' && <SpecialForm menuHeadings={restaurantMenuHeadings} restaurantId={restaurantId} stockLimitKey={stockLimitKey} updateProducts={updateProducts} product={product} menus={menus} key={size.id} size={size} updateOverrideMenuIds={handleDependentMenuIds} />}
        <Col className="col-auto">
          <div className="checkbox c-checkbox mt-2">
            <label>
              <Field
                component="input"
                type="checkbox"
                name={`${stockLimitKey}.${productId}.${size.id}.isOverride`}
                onChange={(e) => {
                  let count = 0;

                  if (e.currentTarget.checked) {
                    // keep track of override counts for products tab form only
                    count = values.count?.[stockLimitKey] ? values.count[stockLimitKey] + 1 : 1;
                    form.change(`${stockLimitKey}.${productId}.${size.id}.isOverride`, true);
                  } else {
                    count = values.count?.[stockLimitKey] ? values.count[stockLimitKey] - 1 : 0;
                    form.change(`${stockLimitKey}.${productId}.${size.id}.isOverride`, false);
                  }

                  if (stockLimitKey !== 'specials' && count >= 0) {
                    form.change(`count.${stockLimitKey}`, count);
                  }
                }}
              />
              <span className="fa fa-check" />
              Override Stock Availability
            </label>
          </div>

          {/* enable option if override is enabled */}
          {values[stockLimitKey]?.[productId]?.[size.id]?.isOverride && (
            <div>
              <div className="mt-2">
                <FormGroup check inline>
                  <label className="c-radio">
                    <Field component="input" className="m-0" type="radio" name={`${stockLimitKey}.${productId}.${size.id}.status`} value={STOCK_CONSTANT.status.SOLD} />
                    <span className="fa fa-circle"></span> Sold Out
                  </label>
                </FormGroup>
                <FormGroup check inline>
                  <label className="c-radio">
                    <Field component="input" type="radio" name={`${stockLimitKey}.${productId}.${size.id}.status`} className="m-0" value={STOCK_CONSTANT.status.HIDE} />
                    <span className="fa fa-circle"></span> Hide
                  </label>
                </FormGroup>

                <FormGroup check inline>
                  <label className="c-radio">
                    <Field component="input" type="radio" name={`${stockLimitKey}.${productId}.${size.id}.status`} className="m-0" value={STOCK_CONSTANT.status.APPLIED} />
                    <span className="fa fa-circle"></span>
                    Apply Stock Limit
                  </label>
                </FormGroup>

                {/*<Field name={`${stockLimitKey}.${productId}.${size.id}.status`}>{({ meta }) => <>{meta.error !== undefined && <p className="text-danger m-0">{meta.error}</p>}</>}</Field>*/}
              </div>

              {/* Applied to */}
              <FormGroup className="mt-3">
                <Label>Apply To</Label>
                <Field name={`${stockLimitKey}.${productId}.${size.id}.appliedTo`} defaultValue={STOCK_CONSTANT.appliedTo.TOTAL}>
                  {({ input }) => {
                    return (
                      <Input
                        {...input}
                        type="select"
                        onChange={(e) => {
                          input.onChange(e);
                          handleStockChange(e.currentTarget.value, `${stockLimitKey}.${productId}.${size.id}`);
                        }}
                      >
                        <option value={STOCK_CONSTANT.appliedTo.TOTAL}>Total Items</option>
                        <option value={STOCK_CONSTANT.appliedTo.MENUS}>Specific Menu</option>
                        <option value={STOCK_CONSTANT.appliedTo.DINE_IN_TAKEAWAY}>Dine In/Takeaways</option>
                      </Input>
                    );
                  }}
                </Field>
              </FormGroup>

              {/* Applied stock to all / Total */}
              <ConditionalRender predicate={values[stockLimitKey]?.[productId][size.id]?.status === STOCK_CONSTANT.status.APPLIED && values[stockLimitKey]?.[productId][size.id]?.appliedTo === STOCK_CONSTANT.appliedTo.TOTAL}>
                <FormGroup>
                  <Label>Current Stock</Label>
                  <Field name={`${stockLimitKey}.${productId}.${size.id}.stock.current`}>{({ meta }) => <>{meta.error !== undefined && <p className="text-danger m-0">{meta.error}</p>}</>}</Field>
                  <div className="input-group h-100">
                    <div className="input-group-prepend">
                      <Button
                        type="button"
                        color="primary"
                        className="input-group-text"
                        onClick={() => form.change(`${stockLimitKey}.${productId}.${size.id}.stock.current`, values[stockLimitKey]?.[productId][size.id]?.stock?.current > 1 ? values[stockLimitKey]?.[productId][size.id]?.stock?.current - 1 : 0)}
                      >
                        <i className="fas fa-minus"></i>
                      </Button>
                    </div>

                    <Field component="input" type="number" name={`${stockLimitKey}.${productId}.${size.id}.stock.current`} readOnly className="form-control counter-label" />

                    <div className="input-group-append">
                      <Button
                        type="button"
                        color="primary"
                        className="input-group-text"
                        onClick={() => form.change(`${stockLimitKey}.${productId}.${size.id}.stock.current`, !isNaN(values[stockLimitKey]?.[productId][size.id]?.stock?.current) ? values[stockLimitKey]?.[productId][size.id]?.stock?.current + 1 : 1)}
                      >
                        <i className="fas fa-plus"></i>
                      </Button>
                    </div>
                  </div>
                </FormGroup>
              </ConditionalRender>

              {/* Menu Dropdown */}
              <ConditionalRender predicate={values[stockLimitKey]?.[productId]?.[size.id]?.appliedTo === STOCK_CONSTANT.appliedTo.MENUS}>
                <FormGroup>
                  <Label>Menus</Label>
                  <Field name={`${stockLimitKey}.${productId}.${size.id}.stock.menus`}>{({ meta }) => <>{meta.error !== undefined && <p className="text-danger m-0">{meta.error}</p>}</>}</Field>
                  <TreeSelect
                    className="form-control tree-select"
                    style={{ width: '100%' }}
                    choiceTransitionName="rc-tree-select-selection__choice-zoom"
                    dropdownStyle={{ height: 100, overflow: 'auto' }}
                    dropdownPopupAlign={{ overflow: { adjustY: 0, adjustX: 0 }, offset: [0, 2] }}
                    treeLine
                    value={Object.keys(values[stockLimitKey]?.[productId]?.[size.id]?.stock?.menus ?? {})}
                    placeholder={'Select menu'}
                    showIcon={false}
                    maxTagTextLength={25}
                    autoClearSearchValue
                    treeData={menuHeadings}
                    treeCheckable
                    showCheckedStrategy={SHOW_PARENT}
                    maxTagCount={20}
                    onChange={(ids) => handleMenuStock(ids, values, productId, size.id)}
                    maxTagPlaceholder={(valueList) => {
                      return `+${valueList.length}`;
                    }}
                  />
                </FormGroup>
              </ConditionalRender>

              {/* Specific Menus Stocks */}
              <ConditionalRender
                predicate={
                  values[stockLimitKey]?.[productId]?.[size.id]?.status === STOCK_CONSTANT.status.APPLIED &&
                  values[stockLimitKey]?.[productId]?.[size.id]?.appliedTo === STOCK_CONSTANT.appliedTo.MENUS &&
                  Object.values(values[stockLimitKey]?.[productId]?.[size.id]?.stock?.menus ?? {}).some((val) => val !== null)
                }
              >
                <FormGroup>
                  <Label>Current Stock</Label>
                  <table>
                    <thead>
                      <th>Menu</th>
                      <th>Qty</th>
                    </thead>
                    <tbody>
                      {Object.keys(values[stockLimitKey]?.[productId]?.[size.id]?.stock?.menus ?? {}).map((menuId) => {
                        return (
                          <tr key={menuId}>
                            <td>{menus[menuId]?.name}</td>
                            <td>
                              <div className="input-group h-100">
                                <div className="input-group-prepend">
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="input-group-text"
                                    onClick={() => form.change(`${stockLimitKey}.${productId}.${size.id}.stock.menus.${menuId}`, values[stockLimitKey]?.[productId]?.[size.id]?.stock?.menus[menuId] > 1 ? values[stockLimitKey]?.[productId]?.[size.id]?.stock?.menus[menuId] - 1 : 0)}
                                  >
                                    <i className="fas fa-minus"></i>
                                  </Button>
                                </div>
                                <input type="number" readOnly className="form-control counter-label" value={values[stockLimitKey]?.[productId]?.[size.id]?.stock?.menus[menuId]} />
                                <div className="input-group-append">
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="input-group-text"
                                    onClick={() => form.change(`${stockLimitKey}.${productId}.${size.id}.stock.menus.${menuId}`, !isNaN(values[stockLimitKey]?.[productId]?.[size.id]?.stock?.menus[menuId]) ? values[stockLimitKey]?.[productId]?.[size.id]?.stock?.menus[menuId] + 1 : 1)}
                                  >
                                    <i className="fas fa-plus"></i>
                                  </Button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </FormGroup>
              </ConditionalRender>

              {/* DineIn / Takeaway */}
              <ConditionalRender predicate={values[stockLimitKey]?.[productId]?.[size.id]?.appliedTo === STOCK_CONSTANT.appliedTo.DINE_IN_TAKEAWAY}>
                <Field name={`${stockLimitKey}.${productId}.${size.id}.stock.dineIn`}>{({ meta }) => <>{meta.error !== undefined && <p className="text-danger m-0">{meta.error}</p>}</>}</Field>
                <FormGroup>
                  <div className="checkbox c-checkbox mt-2">
                    <label>
                      <input
                        type="checkbox"
                        checked={values[stockLimitKey]?.[productId][size.id]?.stock?.dineIn}
                        onChange={(e) => (e.target.checked ? form.change(`${stockLimitKey}.${productId}.${size.id}.stock.dineIn`, 1) : form.change(`${stockLimitKey}.${productId}.${size.id}.stock.dineIn`, undefined))}
                      />
                      <span className="fa fa-check" />
                      Dine In
                    </label>
                  </div>

                  <div className="checkbox c-checkbox mt-2">
                    <label>
                      <input
                        type="checkbox"
                        checked={values[stockLimitKey]?.[productId][size.id]?.stock?.takeaway}
                        onChange={(e) => (e.target.checked ? form.change(`${stockLimitKey}.${productId}.${size.id}.stock.takeaway`, 1) : form.change(`${stockLimitKey}.${productId}.${size.id}.stock.takeaway`, undefined))}
                      />
                      <span className="fa fa-check" />
                      Takeaway
                    </label>
                  </div>
                </FormGroup>
              </ConditionalRender>

              {/* DineIn / Takeaway stock table  */}
              <ConditionalRender predicate={values[stockLimitKey]?.[productId]?.[size.id]?.status === STOCK_CONSTANT.status.APPLIED && (values[stockLimitKey]?.[productId][size.id]?.stock?.takeaway || values[stockLimitKey]?.[productId][size.id]?.stock?.dineIn)}>
                <FormGroup>
                  <Table>
                    <thead>
                      {values[stockLimitKey]?.[productId][size.id]?.stock?.dineIn !== undefined && <th>Dine In</th>}
                      {values[stockLimitKey]?.[productId][size.id]?.stock?.takeaway !== undefined && <th>Takeaway</th>}
                    </thead>
                    <tbody>
                      <tr>
                        <ConditionalRender predicate={values[stockLimitKey]?.[productId][size.id]?.stock?.dineIn !== undefined}>
                          <td>
                            <div className="input-group h-100">
                              <div className="input-group-prepend">
                                <Button
                                  type="button"
                                  color="primary"
                                  className="input-group-text"
                                  onClick={() => form.change(`${stockLimitKey}.${productId}.${size.id}.stock.dineIn`, values[stockLimitKey]?.[productId][size.id]?.stock?.dineIn > 1 ? values[stockLimitKey]?.[productId][size.id]?.stock?.dineIn - 1 : 0)}
                                >
                                  <i className="fas fa-minus"></i>
                                </Button>
                              </div>
                              <input type="text" readOnly className="form-control counter-label" value={values[stockLimitKey]?.[productId][size.id]?.stock?.dineIn} />
                              <div className="input-group-append">
                                <Button
                                  type="button"
                                  color="primary"
                                  className="input-group-text"
                                  onClick={() => form.change(`${stockLimitKey}.${productId}.${size.id}.stock.dineIn`, !isNaN(values[stockLimitKey]?.[productId][size.id]?.stock?.dineIn) ? values[stockLimitKey]?.[productId][size.id]?.stock?.dineIn + 1 : 1)}
                                >
                                  <i className="fas fa-plus"></i>
                                </Button>
                              </div>
                            </div>
                          </td>
                        </ConditionalRender>

                        <ConditionalRender predicate={values[stockLimitKey]?.[productId][size.id]?.stock?.takeaway !== undefined}>
                          <td>
                            <div className="input-group h-100">
                              <div className="input-group-prepend">
                                <Button
                                  type="button"
                                  color="primary"
                                  className="input-group-text"
                                  onClick={() => form.change(`${stockLimitKey}.${productId}.${size.id}.stock.takeaway`, values[stockLimitKey]?.[productId][size.id]?.stock?.takeaway > 1 ? values[stockLimitKey]?.[productId][size.id]?.stock?.takeaway - 1 : 0)}
                                >
                                  <i className="fas fa-minus"></i>
                                </Button>
                              </div>
                              <input type="number" readOnly className="form-control counter-label" value={values[stockLimitKey]?.[productId][size.id]?.stock?.takeaway} />
                              <div className="input-group-append">
                                <Button
                                  type="button"
                                  color="primary"
                                  className="input-group-text"
                                  onClick={() => form.change(`${stockLimitKey}.${productId}.${size.id}.stock.takeaway`, !isNaN(values[stockLimitKey]?.[productId][size.id]?.stock?.takeaway) ? values[stockLimitKey]?.[productId][size.id]?.stock?.takeaway + 1 : 1)}
                                >
                                  <i className="fas fa-plus"></i>
                                </Button>
                              </div>
                            </div>
                          </td>
                        </ConditionalRender>
                      </tr>
                    </tbody>
                  </Table>
                </FormGroup>
              </ConditionalRender>

              <ConditionalRender predicate={values[stockLimitKey]?.[productId]?.[size.id]?.status === STOCK_CONSTANT.status.APPLIED}>
                <FormGroup>
                  <Label>Out of Stock Display Setting</Label>
                  <Field component="select" className="form-control" name={`${stockLimitKey}.${productId}.${size.id}.outOfStockLabel`} defaultValue={STOCK_CONSTANT.outOfStockLabel.SOLD_OUT}>
                    <option value={STOCK_CONSTANT.outOfStockLabel.SOLD_OUT}>Sold Out</option>
                    <option value={STOCK_CONSTANT.outOfStockLabel.HIDE}>Hide</option>
                  </Field>
                </FormGroup>
              </ConditionalRender>
            </div>
          )}
        </Col>
      </CardBody>
    </Card>
  );
}

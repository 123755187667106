import firebase from 'firebase/compat/app';
import _, { flatten, map, values } from 'lodash';
import moment from 'moment';
import { OrderModeType } from '../components/Layout/OmsSelector';
import { getConfig } from '../core/utilities';
import { files } from '../resbutler-utils';
import { Booking } from '../resbutler-utils/types/Booking';
import { DocketFromDocket, DocketFromDocketDocketItems, OMSDisplayDocket, OMSDocketGeneratedFrom } from '../resbutler-utils/types/Docket';
import { MenuHeadings } from '../resbutler-utils/types/Menu';
import { Docket, OrderItem, OrderItemStatus, OrderMode, OrderSource, OrderType, Orders } from '../resbutler-utils/types/Order';
import { Printer } from '../resbutler-utils/types/Printers';
import { Restaurants } from '../resbutler-utils/types/Restaurant';
import { Customer } from '../resbutler-utils/types/customer';
import { Product, ProductGroups, ProductSizes } from '../resbutler-utils/types/product';
import { generateDocket } from '../resbutler-utils/utils/docketUtils';

export const getDocketColorIndicatorCSSVariables = (docket: OMSDisplayDocket) => {
  const orderSource = docket.orderSource;
  const isSpaceManagementOrder = docket.bookingId || docket.bookingRef;

  // Takeaway
  if (docket.orderType === OrderType.Pickup) {
    return 'var(--docket-indicator-green)';
  }

  // Space management
  if (isSpaceManagementOrder) {
    return 'var(--docket-indicator-blue)';
  }

  // QR
  if (orderSource === OrderSource.QRCode) {
    return 'var(--docket-indicator-red)';
  }

  // Till
  if (orderSource === OrderSource.Till) {
    return '#b119b6';
  }

  return 'var(--docket-indicator-default)';
};

const shorthand = {
  TABLE_SERVICE: 'TS',
  COUNTER_SERVICE: 'CS',
  QR_CODE: 'QR',
  SPACE_MANAGEMENT: 'SM',
  TAKE_AWAY: 'TA',
  PICKUP: 'PU',
  DELIVERY: 'DL',
};

export const getDocketCodeIndicator = (docket: OMSDisplayDocket) => {
  const orderSource = docket.orderSource;
  const orderType = docket.orderType;
  let code = '';
  // QR
  if (orderSource === OrderSource.QRCode) {
    if (orderType === OrderType.TableService) code = `${shorthand.TABLE_SERVICE}-${shorthand.QR_CODE}`;
    if (orderType === OrderType.CounterService) code = `${shorthand.COUNTER_SERVICE}-${shorthand.QR_CODE}`;
  }
  // Takeaway
  else if (orderSource === OrderSource.TakeawayWidget) {
    if (orderType === OrderType.TableService) code = `${shorthand.TAKE_AWAY}-${shorthand.PICKUP}`;
    if (orderType === OrderType.CounterService) code = `${shorthand.TAKE_AWAY}-${shorthand.DELIVERY}`;
  }
  // Space management
  else if (orderSource === OrderSource.OrderingWidget) {
    if (orderType === OrderType.TableService) code = `${shorthand.TABLE_SERVICE}-${shorthand.SPACE_MANAGEMENT}`;
    if (orderType === OrderType.CounterService) code = `${shorthand.COUNTER_SERVICE}-${shorthand.SPACE_MANAGEMENT}`;
  }
  return code;
};

export const getBookingById = (bookingId: string, bookingsByMeal: { [mealId: string]: Booking[] }): Booking => {
  const allBookings = flatten(map(bookingsByMeal, (mealBookings) => mealBookings));
  return allBookings && allBookings.length ? allBookings.find((booking) => booking._key === bookingId) : null;
};

export const getOMSDockets = (
  dbDockets: Docket[],
  allOrders: Orders,
  printers: Printer[],
  filterPrinterIds: string[],
  allBookingsByMeal: { [mealId: string]: Booking[] },
  orderMode: OrderModeType,
  products: Product[],
  sizes: ProductSizes,
  menuHeadings: MenuHeadings,
  restaurants: Restaurants,
  productGroups: ProductGroups,
  customers: Customer[]
) => {
  let omsDockets: OMSDisplayDocket[] = [];
  const allDockets: OMSDisplayDocket[] = _.map(dbDockets, (docket) => {
    const docketItems: DocketFromDocketDocketItems[] = _.map(docket.docketItems, (docketItem, j) => ({
      ...docketItem,
      docketItemId: j,
      docketId: docket._key,
      orderStatus: allOrders[docketItem.orderId]?.orderItems?.[docketItem.orderItemId]?.orderStatus,
    }));
    const orderedDocketItems = [
      //Put orders with no course group first in order
      ..._.orderBy(
        docketItems.filter((d) => d.courseGroupIndex === null),
        ['heading', 'name'],
        ['asc', 'asc']
      ),
      ..._.orderBy(
        docketItems.filter((d) => d.courseGroupIndex !== null),
        ['courseGroupIndex', 'heading', 'name'],
        ['asc', 'asc', 'asc']
      ),
    ];
    return {
      ...docket,
      docketId: docket._key,
      docketGeneratedFrom: OMSDocketGeneratedFrom.Docket,
      docketItems: orderedDocketItems,
    };
  });
  const orderingDockets = allDockets.filter((d) => d.orderSource === OrderSource.OrderingWidget);
  const takeawayDockets = allDockets.filter((d) => d.orderSource === OrderSource.TakeawayWidget);
  const tillDockets = allDockets.filter((d) => d.orderSource === OrderSource.Till);
  const qrDockets = allDockets.filter((d) => d.orderSource === OrderSource.QRCode);

  let filteredOrderingDockets: OMSDisplayDocket[] = [],
    filteredTakeawayDockets: OMSDisplayDocket[] = [],
    filteredTillDockets: OMSDisplayDocket[] = [],
    filteredQrDockets: OMSDisplayDocket[] = [];
  const availableOrders = _.map({ ...allOrders }, (order, key) => ({ ...order, _key: key })).filter((o) => {
    return o.bookingId !== '' && o.bookingId !== undefined && getBookingById(o.bookingId, allBookingsByMeal)?.status <= files.statuses.seated.value;
  });

  // create dockets by order mode type
  if (orderMode === OrderModeType.PreOrder || orderMode === OrderModeType.CurrentBasketOrder) {
    if (orderMode === OrderModeType.PreOrder) {
      filteredTakeawayDockets = takeawayDockets.filter((docket: DocketFromDocket) => docket.docketGenerationTime > Date.now());
      filteredTillDockets = tillDockets.filter((docket: DocketFromDocket) => docket.docketGenerationTime > Date.now());
      filteredQrDockets = qrDockets.filter((docket: DocketFromDocket) => docket.docketGenerationTime > Date.now());
    }
    filteredOrderingDockets = createPreOrderOrBasketDockets(availableOrders, allBookingsByMeal, restaurants, orderMode, products, sizes, menuHeadings, printers, productGroups, customers);
  } else {
    filteredOrderingDockets = orderingDockets;
    filteredTakeawayDockets = takeawayDockets.filter((docket: DocketFromDocket) => docket.docketGenerationTime <= Date.now());
    filteredTillDockets = tillDockets.filter((docket: DocketFromDocket) => docket.docketGenerationTime <= Date.now());
    filteredQrDockets = qrDockets.filter((docket: DocketFromDocket) => docket.docketGenerationTime <= Date.now());
  }

  const filteredDockets: OMSDisplayDocket[] = [...filteredOrderingDockets, ...filteredTakeawayDockets, ...filteredTillDockets, ...filteredQrDockets];
  const filterAreaIds = _.chain(filterPrinterIds)
    .map((p) => printers[p]?.printerAreas || [])
    .filter((areaId) => areaId)
    .flatten()
    .uniq()
    .value();
  //Filter dockets by area id from docket and printer.
  const filteredDocketsByAreaId = filteredDockets.filter((docket) => {
    if(docket.orderType === OrderType.Pickup) return true; // pickup order (aka takeaway) do not have area id
    if (_.intersection(filterAreaIds, docket.printerAreaIds).length === 0) return false;
    return true;
  });
  const filterMacAddresses = _.chain(filterPrinterIds)
    .map((p) => printers[p]?.macAddress)
    .filter((mac) => mac)
    .value();
  //Filter docketItems in docket by printer
  const filteredDocketByMacAddress = filteredDocketsByAreaId
    .map((docket) => {
      if (docket.docketGeneratedFrom === OMSDocketGeneratedFrom.Docket) {
        return {
          ...docket,
          docketItems: getDocketItemsByMacAddresses(filterMacAddresses, docket.docketItems),
        };
      }
      return docket;
    })
    .filter((docket) => docket.docketItems.length);

  omsDockets = _.orderBy(filteredDocketByMacAddress, ['docketGenerationTime'], ['asc']);

  return omsDockets;
};

function createPreOrderOrBasketDockets(availableOrders, allBookingsByMeal, restaurants, orderMode, products, sizes, menuHeadings, printers, productGroups, customers) {
  let orders = [];
  if (orderMode === OrderModeType.PreOrder) {
    orders = availableOrders.filter((o) => Object.values(o.orderItems).some((oi: OrderItem) => oi.orderStatus === OrderItemStatus.InBasket && oi.orderMode === OrderMode.PreService && oi.paidQuantity === oi.quantity));
  } else {
    orders = availableOrders.filter((o) => Object.values(o.orderItems).some((oi: OrderItem) => (oi.orderStatus === OrderItemStatus.InBasket || oi.orderStatus === OrderItemStatus.WaitingToBeSent) && oi.quantity > oi.paidQuantity));
  }
  const { client } = getConfig();

  const dockets = orders.map((o) => {
    const restaurant = restaurants[o.restaurantId];
    const booking = allBookingsByMeal[o.mealId].find((b) => b._key === o.bookingId);
    const keyedOrders = _.keyBy(orders, (o) => o._key);
    const keyedProducts = _.keyBy(products, (p) => p.id);
    const docket: Docket = generateDocket(
      o.bookingId,
      o.functionBookingId,
      o.createdAt,
      moment().unix(),
      values(o.orderItems),
      null,
      keyedOrders,
      [booking.alg.areaId],
      booking,
      o.orderNumber,
      null,
      null,
      [],
      true,
      o.operatorId,
      keyedProducts,
      customers,
      sizes,
      menuHeadings,
      printers,
      productGroups,
      o.restaurantId,
      restaurant.zoneId,
      () => {
        return firebase.firestore().collection(`${client}/ordering/docketItems`).doc().id;
      }
    );
    return {
      ...docket,
      docketItems: values(docket.docketItems),
      docketGeneratedFrom: OMSDocketGeneratedFrom.Order,
      docketId: docket._key,
    };
  });
  return dockets as OMSDisplayDocket[];
}

function getDocketItemsByMacAddresses(macAddresses: string[], docketItems: DocketFromDocketDocketItems[]) {
  const filteredDocketItems: DocketFromDocketDocketItems[] = [];
  for (let docketItemIndex = 0; docketItemIndex < docketItems.length; docketItemIndex++) {
    const docketItem = docketItems[docketItemIndex];
    const docketMacAddresses: string[] = docketItem.printing.map((p) => p.macAddress);
    if (_.intersection(macAddresses, docketMacAddresses).length > 0) filteredDocketItems.push(docketItem);
  }

  return filteredDocketItems;
}

export enum ProductCategoryId {
  // id1: productId,
  // id2: sizeId,
  // id3: orderItemId,
  // id4: orderId,
  // id6: menuHeadingId ??
  // id7: menuId ??,
  // id8: foodPackageId ??,
  // id9: beveragePackageId ??,
  // id10: foodAndBeveragePackageId ??
  // id11: beverageMenuPackageId ??
  SaleProducts = 1,
  // id1: menuId, id2: courseId, id3: productId
  PrepaidMenus = 2,
  MarketingPromotional = 3,
  CustomerServiceExpense = 6,
  OperationalExpense = 7,
  PettyCashExpense = 8,
  ContingentLiability = 9,
  OtherRevenue = 10,
  FeesAndCharges = 11,
  // id1: menuId, id2: courseId, id3: productId
  Deposits = 12,
  // id1: giftCertificateId
  // id2: accountId
  GiftCertificates = 13,
  Inventory = 14,
  CreditAccounts = 15,
  Cashout = 16,
}

export const productTreeCategoryList = {
  marketingPromotional: {
    name: "Marketing & Promotional",
    value: ProductCategoryId.MarketingPromotional,
  },
  customerServiceExpense: {
    name: "Customer Service Expense",
    value: ProductCategoryId.CustomerServiceExpense,
  },
  operationalExpense: {
    name: "Operational Expense",
    value: ProductCategoryId.OperationalExpense,
  },
};

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import { pickBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { catchExceptionCallback, getConfig } from '../core/utilities';
import { setRestaurants } from '../store/reducers/rootSlice';

function useRestaurants(firebaseLoading) {
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  
  useEffect(() => {
    async function loadAsync() {
      try {
        const { tenantId } = getConfig();
        setLoading(true);
        const snap = await firebase.database().ref(`${tenantId}/restaurants`).once('value');
        dispatch(setRestaurants(pickBy(snap.val(), (r) => r.enabled)));
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    }
    if (!firebaseLoading) loadAsync();
  }, [firebaseLoading]);

  return [loading];
}

export default useRestaurants;
